import { useTitle } from '../components/TitleProvider'

interface TextPageHeaderProps {
    title: string
    tabTitle?: string
    subtitle?: string
}

/**
 * Formats a page header, and updates the browser tab text.
 *
 * Title will set both the page header and the browser tab text.
 * If you want different text in the tab, you can set tabTitle.
 */
export function TextPageHeader({
    title,
    tabTitle,
    subtitle
}: TextPageHeaderProps) {
    useTitle(tabTitle || title)
    return (
        <div className="mb-5">
            <h1 className="text-gray-950 not-prose pt-5 text-left mb-2">
                {title}
            </h1>
            {subtitle && (
                <h2 className="text-gray-700 not-prose mt-2 text-left ">
                    {subtitle}
                </h2>
            )}
        </div>
    )
}
