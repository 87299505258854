import React, { useEffect, useRef, useState } from 'react'
import {
    useTiktokAuthCodeToAccessTokenMutation,
    TiktokAuthCodeToAccessTokenMutation,
    useSaveAuthorisationCodeMutation,
    useTiktokCreateUserAccountWithAuthMutation,
    TiktokCreateUserAccountWithAuthMutation,
} from '../../api/client'
import TiktokAdAccountsSelectionPage from './TiktokAdAccountsSelectionPage'
import SimpleLoader from '../../components/SimpleLoader'
import useQueryParams from '../../hooks/useQueryParams'
import { useNavigate } from 'react-router-dom'

/**
 * Handles the redirect from the OAuth2 provider after they have authorised Arwen.
 *
 * This page has some cruft. It uses two mutations:
 * 1. saveAuthorisationCode
 * 2. authoriseAccountWithAuthCode
 *
 * The first is the legacy TypeScript mutation, and the second is the new GraphQL mutation.
 * The second mutation is used for TikTok, and the first is used for everything else.
 *
 * The two mutations work rather differently. The legacy mutation always redirects to the home page.
 * The new mutation does not, but in fact returns JSX to inform the user what happened. This is
 * also not trivial, because authorisation might be updates to profiles that already exist, or
 * we might have added new profiles. So it lets the user know exactly what happened here.
 */
function AuthoriseResponsePage() {
    const navigate = useNavigate()
    const query = useQueryParams()
    const [newAccounts, setNewAccounts] = useState<Array<number | null>>([])
    const [updatedAccounts, setUpdatedAccounts] = useState<
        Array<number | null>
    >([])

    const [
        saveAuthorisationCodeMutation,
        { loading: saveLoading, error: saveError },
    ] = useSaveAuthorisationCodeMutation({
        onCompleted: function (response) {
            // We'll set these so we can, from this component, render
            // HTML to let the user know we've added or updated account auth..
            setNewAccounts(response.saveAuthorisationCode?.new || [])
            setUpdatedAccounts(response.saveAuthorisationCode?.updated || [])
        },
    })

    const [accountsResponse, setAccountsResponse] =
        useState<TiktokAuthCodeToAccessTokenMutation | null>(null)
    const [authorizedAccount, setAuthorizedAccount] =
        useState<TiktokCreateUserAccountWithAuthMutation | null>(null)
    const [
        tiktokAuthCodeToAccessTokenMutation,
        { loading: authLoading, error: authError },
    ] = useTiktokAuthCodeToAccessTokenMutation({
        onCompleted: function (data) {
            setAccountsResponse(data)
        },
    })
    const [
        tiktokAccountsAuthCodeToAccessTokenMutation,
        { loading: accountsAuthLoading, error: accountsAuthError },
    ] = useTiktokCreateUserAccountWithAuthMutation({
        onCompleted: function (data) {
            setAuthorizedAccount(data)
        },
    })

    // It's essential this only runs once. We'll use a ref to make this happen.
    const hasRunRef = useRef(false)
    // This examines the query parameters and figures out which platform this redirect
    // is coming from.
    useEffect(() => {
        if (hasRunRef.current) {
            return
        }
        hasRunRef.current = true

        let source = query.get('source')
        const state = query.get('state')
        const scope = query.get('scope')
        let authCode: string | null = null
        let accountId: string | null = null
        let authVerifier: string | null = null

        // TikTok Ads
        if (state === 'tiktokad') {
            const auth_code = query.get('auth_code')
            const code = query.get('code')
            if (!auth_code) {
                console.error('No auth code')
                return
            }
            if (!code) {
                console.error('No code')
                return
            }
            // Unlike the other platforms, with TikTok we're going to call the new-world mutation.
            tiktokAuthCodeToAccessTokenMutation({
                variables: {
                    authCode: auth_code,
                    code: code,
                },
            })
        }
        // TikTok Accounts
        if (state === 'tiktokuser') {
            const code = query.get('code')
            if (code === null) {
                console.error('No code')
                return
            }
            tiktokAccountsAuthCodeToAccessTokenMutation({
                variables: {
                    authCode: code,
                    code: code,
                    redirectUri: window.location.origin,
                },
            })
        }
        // Twitter
        else if (source === 'twitter') {
            accountId = query.get('accountId')
            authCode = query.get('oauth_token')
            authVerifier = query.get('oauth_verifier')
        }
        // Google
        else if (scope) {
            authCode = query.get('code')
            accountId = query.get('state')
            source = 'youtube'
        }
        // Facebook
        else if (state) {
            accountId = query.get('state')
            authCode = query.get('code')
        }

        if (!authCode || !accountId) {
            return
        }

        const baseUrl = `${window.location.protocol}//${window.location.host}/`
        const accountIdNumber = parseInt(accountId, 10)
        if (isNaN(accountIdNumber)) {
            console.error('Invalid accountId')
            return
        }

        saveAuthorisationCodeMutation({
            variables: {
                accountId: accountIdNumber,
                authCode,
                authVerifier,
                socialMediaServiceName: source,
                baseUrl,
            },
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    //disabling eslint check, we only need this to fire once.

    // Accounts response will only be populated if we're using the new mutation.

    const newAccountsWereCreated =
        accountsResponse &&
        accountsResponse.tiktokAuthCodeToAccessToken.adAccounts.length

    // If we have a response and there are new or existing accounts that we've updated.
    if (newAccountsWereCreated) {
        return (
            <TiktokAdAccountsSelectionPage
                adAccounts={
                    accountsResponse.tiktokAuthCodeToAccessToken.adAccounts
                }
                sessionKey={
                    accountsResponse.tiktokAuthCodeToAccessToken.key.key
                }
            />
        )
    }

    // But if we're using the old mutation, we'll just show a loading spinner.
    const loading = saveLoading || authLoading || accountsAuthLoading
    const error = saveError || authError || accountsAuthError

    let contents
    // By displaying here we're not able to refresh to see this page, because we're not on that kind of route.
    // SOMEDAY: we redirect to a route that takes a list of new and updated IDs, and displays them. This might be too
    // long for a URL, so we might need to store this in the session, or local storage, or recoil. But this is
    // probably good for now.
    if (
        newAccounts?.length > 0 ||
        updatedAccounts?.length > 0 ||
        authorizedAccount?.tiktokCreateUserAccountWithAuth
    ) {
        navigate('/account/new/authorised')
    } else {
        contents = (
            <>
                <SimpleLoader loading={loading} />
                {error && <p>{error.message}</p>}
            </>
        )
    }

    return (
        <div className="m-10 bg-white mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:pt-6 lg:pb-12 lg:px-16 space-y-8 divide-y sm:space-y-5 prose">
            <div className="flex flex-col">{contents}</div>
        </div>
    )
}

export default AuthoriseResponsePage
