import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useGetSocialMediaServicesQuery, useGetTeamsQuery } from '../api/client'
import { canUseBulkActions } from '../components/content/bulk-actions'
import ContentList from '../components/content/ContentList'
import ContentSearchBar from '../components/content/search/ContentSearchBar'
import { toastError } from '../components/Notification'
import SimpleLoader from '../components/SimpleLoader'
import useContentSearchParams from '../hooks/useContentQueryParams'
import { isUserAdminState, userState } from '../store/DomainStore'

function ContentSearchPage() {
    const {
        searchParameters,
        onSearchParameterChange,
        allSocialMediaServices,

        setAllSocialMediaServices
    } = useContentSearchParams()

    const [admin] = useRecoilState(isUserAdminState)
    const [user] = useRecoilState(userState)

    const { data: getTeamsData, loading: getTeamsLoading } = useGetTeamsQuery({
        onError: () => {
            toastError('Error fetching user teams. Please contact support.')
        }
    })

    const hasMoreThanOneTeam: boolean = !!(
        getTeamsData?.getTeams?.length && getTeamsData?.getTeams?.length > 1
    )

    // We want to show the Team drop down only if it will be useful to users. Which means they are either an admin or they are part of more than one team.
    const showTeamSearch: boolean = admin || hasMoreThanOneTeam

    // The selected social media services
    const {
        data: socialMediaServicesData,
        loading: getSocialMediaServicesLoading
    } = useGetSocialMediaServicesQuery({
        onError: () => {
            toastError(
                'Error fetching social media information. Please contact support.'
            )
        }
    })

    useEffect(() => {
        if (socialMediaServicesData?.getSocialMediaServices) {
            setAllSocialMediaServices(
                socialMediaServicesData?.getSocialMediaServices
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialMediaServicesData?.getSocialMediaServices])

    const enableBulkActions = canUseBulkActions(user)

    const loading = getTeamsLoading || getSocialMediaServicesLoading

    if (loading) {
        return <SimpleLoader loading={true} />
    }

    return (
        <>
            <ContentSearchBar
                searchParameters={searchParameters}
                onSearchParameterChange={onSearchParameterChange}
                allSocialMediaServices={allSocialMediaServices}
                showProfileSearch={true}
                showSocialMediaServiceSearch={true}
                showEmotionsSearch={false}
                showTopicsSearch={false}
                showTeamSearch={showTeamSearch}
                showReadStatusSearch={user.canUseReadStatus}
            />
            <ContentList
                searchParameters={searchParameters}
                showOwningAccount={true}
                enableBulkActions={enableBulkActions}
            ></ContentList>
        </>
    )
}

export default ContentSearchPage
