import clsx from 'clsx'
import { AccountRecord } from '../../api/client'
import { faIconName } from '../../util/classNames'

type Props = {
    account: AccountRecord
}

function AccountNameTitle({ account }: Props) {
    let iconName = faIconName(account?.socialMediaServiceShortName || '', true)
    const accountService = account?.socialMediaServiceDisplayName

    const accountNameDisplay =
        account.accountName && accountService ? `${account.accountName}` : ''
    const accountDisplay = (
        <>
            {accountNameDisplay}
            <i
                data-tooltip-id="tooltip-id"
                data-tooltip-content={`This content comes from ${account.socialMediaServiceDisplayName}`}
                className={`fab ml-4 ${iconName}`}
            ></i>{' '}
        </>
    )

    return (
        <div className="mt-2 text-gray-700 flex self-center ">
            <h2 className={clsx('pl-0', 'text-2xl text-gray-600 font-header')}>
                {accountDisplay}
            </h2>
        </div>
    )
}

export default AccountNameTitle
