import {
    HomeIcon,
    UserGroupIcon,
    QuestionMarkCircleIcon,
    InformationCircleIcon,
    ChartBarIcon,
    AcademicCapIcon,
    MagnifyingGlassIcon
} from '@heroicons/react/24/outline'

export const NAV_TITLE_HOMEPAGE = 'Homepage'
export const NAV_TITLE_CONTENT_SEARCH = 'Comment Search'
export const NAV_TITLE_TEAM = 'Team'
export const NAV_TITLE_REPORT = 'Reporting'
export const NAV_TITLE_SUPPORT = 'Support'
export const NAV_TITLE_ABOUT = 'About'
export const NAV_TITLE_INTELLIGENCE = 'Engage'

export function getNavigationItems(
    onHomePage: boolean,
    onContentSearchPage: boolean,
    onTeamPage: boolean,
    onReportPage: boolean,
    onSupportPage: boolean,
    onAboutPage: boolean,
    onIntelligencePage: boolean
) {
    return [
        {
            name: NAV_TITLE_HOMEPAGE,
            href: '#',
            icon: HomeIcon,
            current: onHomePage
        },
        {
            name: NAV_TITLE_CONTENT_SEARCH,
            href: '#/content-search',
            icon: MagnifyingGlassIcon,
            current: onContentSearchPage
        },
        {
            name: NAV_TITLE_REPORT,
            href: '#/report',
            icon: ChartBarIcon,
            current: onReportPage
        },
        {
            name: NAV_TITLE_TEAM,
            href: '#/team',
            icon: UserGroupIcon,
            current: onTeamPage
        },
        {
            name: NAV_TITLE_SUPPORT,
            href: '#/support',
            icon: QuestionMarkCircleIcon,
            current: onSupportPage
        },
        {
            name: NAV_TITLE_ABOUT,
            href: '#/about',
            icon: InformationCircleIcon,
            current: onAboutPage
        },
        {
            name: NAV_TITLE_INTELLIGENCE,
            href: '#/engage',
            icon: AcademicCapIcon,
            current: onIntelligencePage
        }
    ]
}
