import { useAuthenticator } from '@aws-amplify/ui-react'
import { Link, useLocation } from 'react-router-dom'
import ProfileButton from './profile/ProfileButton'
import HeaderPageTitle from './sidebar/HeaderPageTitle'
import { whichPageAreWeOn } from './sidebar/page-name'

import { Transition } from '@headlessui/react'
import logoSmall from '../assets/brand/arwen-icon-red-small.png'

type Props = { showLogo: boolean }

export default function Header(props: Props) {
    let { showLogo } = props
    const { signOut } = useAuthenticator((context) => [context.route])
    const location = useLocation()
    const paths = location.pathname.split('/')

    function showTitle() {
        const pages = whichPageAreWeOn(paths)

        return pages.onAccountPage
    }

    return (
        <div className="header flex flex-1 justify-between px-4">
            <Transition
                show={showLogo}
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Link to="/">
                    <div>
                        <img
                            alt="ARWEN logo - click to go home"
                            src={logoSmall}
                            className="pt-2"
                            style={{
                                minWidth: '43px',
                                width: '43px',
                                position: 'absolute'
                            }}
                        />
                    </div>
                </Link>
            </Transition>

            <div className="flex flex-1">
                {showTitle() && <HeaderPageTitle showLogo={showLogo} />}
            </div>

            {/* z-index set to place profile beneath notification container */}
            <div className="z-0 ml-4 mr-2 flex items-center xl:ml-6">
                <ProfileButton signOut={signOut} />
            </div>
        </div>
    )
}
