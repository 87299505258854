import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Field,
    Transition,
} from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { AccountRecord, useSearchTeamsQuery } from '../../api/client'
import ArwenComboBoxOption from '../inputs/ArwenComboBoxOption'
import { ComboboxMenuOption } from '../inputs/ComboboxMenuOption'
import SimpleModal from '../SimpleModal'
import Spinner from '../Spinner'
import DuplicateModerationConfigurationModal from './DuplicateModerationConfigurationModal'

type Prop = {
    account: AccountRecord
}

const DUMMY_SELECTED_TEAM: ComboboxMenuOption = {
    id: -1,
    name: '',
}

export default function DuplicateModerationConfiguration(props: Prop) {
    const { account } = props
    const [copyModalOpen, setCopyModalOpen] = useState(false)
    const [selectedTeam, setSelectedTeam] =
        useState<ComboboxMenuOption>(DUMMY_SELECTED_TEAM)
    const [searchTerm, setSearchTerm] = useState('')

    const { data: searchTeamsData, loading: searchTeamsLoading } =
        useSearchTeamsQuery({
            variables: { params: { searchTerm: searchTerm } },
        })

    const handleTeamChange = (team: ComboboxMenuOption) => {
        if (team) {
            setSelectedTeam(team)
        }
    }

    const searchResult = searchTeamsData?.searchTeams ?? []

    const options = searchResult.map((team) => {
        return { id: team.id, name: team.name }
    })

    return (
        <div className="ml-3">
            <p className="pb-0 mb-0">
                You can copy this profile's moderation boundaries and keywords
                to other profiles below
            </p>
            <div className="flex flex-row items-end">
                <div className="mr-3">Copy to team:</div>
                <Field>
                    <Combobox
                        value={selectedTeam}
                        onChange={handleTeamChange}
                        onClose={() => setSearchTerm('')}
                        multiple={false}
                    >
                        {({ open }) => (
                            <div className="relative mt-2">
                                <ComboboxInput
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-950 shadow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                    displayValue={(team: ComboboxMenuOption) =>
                                        (team && team.name) || ''
                                    }
                                />
                                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </ComboboxButton>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <ComboboxOptions className="list-none px-0 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {searchTeamsLoading ? (
                                            <ComboboxOption
                                                value="Loading"
                                                disabled={true}
                                            >
                                                <div className="pl-3 text-primary">
                                                    <Spinner size={4} />
                                                </div>
                                            </ComboboxOption>
                                        ) : null}
                                        {options.map((option) => (
                                            <ComboboxOption
                                                key={option.id}
                                                value={option}
                                            >
                                                {({ focus, selected }) => (
                                                    <ArwenComboBoxOption
                                                        option={option}
                                                        selected={selected}
                                                        focus={focus}
                                                    />
                                                )}
                                            </ComboboxOption>
                                        ))}
                                    </ComboboxOptions>
                                </Transition>
                            </div>
                        )}
                    </Combobox>
                </Field>
                {selectedTeam && selectedTeam.id !== DUMMY_SELECTED_TEAM.id && (
                    <input
                        type="submit"
                        value="Copy"
                        onClick={() => {
                            setCopyModalOpen(true)
                        }}
                        className={clsx(
                            'ml-4',
                            'text-base inline-flex items-center px-3 py-2 leading-4 ',
                            'border  rounded-md shadow-sm ',
                            'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ',
                            'drop-shadow-sm hover:drop-shadow-md',
                            'text-gray-950 ring-gray-300 hover:bg-gray-200',
                            'transition ease-in-out duration-150'
                        )}
                    />
                )}
            </div>

            <SimpleModal
                open={copyModalOpen}
                onClose={() => {
                    setCopyModalOpen(false)
                }}
            >
                {selectedTeam && selectedTeam.id !== DUMMY_SELECTED_TEAM.id && (
                    <DuplicateModerationConfigurationModal
                        account={account}
                        teamId={selectedTeam.id}
                        setCopyModalOpen={setCopyModalOpen}
                    />
                )}
            </SimpleModal>
        </div>
    )
}
