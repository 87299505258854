import { UserView } from '../api/client'
import { Referrer } from './Referrer'
import Team from './Team'

/**
 * This User Model was originally created to store which user was logged in when the app initialised.
 */
type User = {
    id?: number
    username: string
    identity: string
    createdOn: Date
    stripeCustomerId?: string
    stripeProductId?: string
    stripeSubscriptionId?: string
    stripeSubscriptionStatus?: string
    shouldProvisionAccess: boolean
    team: Team
    dashboardId?: number
    dashboardEnabled?: boolean
    referrer?: Referrer
    canReplyToContent: boolean
    canLikeContent: boolean
    intelligencePageViewer: boolean
    canUseReadStatus: boolean
    feedsEnabled: boolean
}

export default User

/**
 * This more complex user was created to represent the user data backing the User Admin UI pages.
 * It has more information than the User above.
 *
 * FIXME: consider merging these two Users. This should be easy and would enable use to remove the User fetching code from Tenmoku.
 */
export type UserAdminView = {
    id: number
    username: string
    createdOn: Date
    updatedAt?: Date
    stripeCustomerId?: string
    stripeSubscriptionId?: string
    stripeSubscriptionStatus?: string
    dashboardId?: string
    dashboardEnabled: boolean
    referrerId?: number
    allowAccessOverride: boolean
    admin?: boolean
    canLikeContent?: boolean
    canReplyToContent?: boolean
    intelligencePageViewer?: boolean
    canUseReadStatus?: boolean
    feedsEnabled?: boolean
}

export function mapToUserAdminView(userView: UserView): UserAdminView {
    return {
        id: userView.id,
        username: userView.username,
        createdOn: userView.createdOn,
        updatedAt: userView.updatedAt,
        stripeCustomerId: userView.stripeCustomerId
            ? userView.stripeCustomerId
            : undefined,
        stripeSubscriptionId: userView.stripeSubscriptionId
            ? userView.stripeSubscriptionId
            : undefined,
        stripeSubscriptionStatus: userView.stripeSubscriptionStatus
            ? userView.stripeSubscriptionStatus
            : undefined,
        dashboardId: userView.dashboardId ? userView.dashboardId : undefined,
        dashboardEnabled: userView.dashboardEnabled
            ? userView.dashboardEnabled
            : false,
        referrerId: userView.referrerId ? userView.referrerId : undefined,
        allowAccessOverride: userView.allowAccessOverride
            ? userView.allowAccessOverride
            : false,
        canLikeContent: userView.canLikeContent
            ? userView.canLikeContent
            : undefined,
        canReplyToContent: userView.canReplyToContent
            ? userView.canReplyToContent
            : undefined,
        intelligencePageViewer: userView.intelligencePageViewer
            ? userView.intelligencePageViewer
            : undefined,
        canUseReadStatus: userView.canUseReadStatus
            ? userView.canUseReadStatus
            : undefined,
        feedsEnabled: userView.feedsEnabled
            ? userView.feedsEnabled
            : undefined,
        admin: userView.admin ? userView.admin : undefined,
    }
}
