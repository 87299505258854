import clsx from 'clsx'
import { format } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'

type Props = {
    id: string
    dateTime: Dayjs | undefined
    className?: string
    onChange: (dateTime: Dayjs) => void
}

function DateTimePicker(props: Props) {
    const { id, dateTime, onChange, className } = props

    // The default date time picker supports only a local version of the ISO standard
    // That is YYY-MM-DDThh:mm:ss.SSS - that is without the Z.
    // If we include the seconds and milliseconds then the picker will also display these which is a confusing interface.
    // So we convert the date to a string in the format YYYY-MM-DDThh:mm
    // This is basically ISO 8601 without the seconds and milliseconds.
    if (!dateTime) {
        return <>&nbsp;</>
    }
    const dayjsDate =
        typeof dateTime.toDate === 'function' ? dateTime : dayjs(dateTime)

    let formattedDate: string | undefined = undefined
    if (dayjsDate) {
        formattedDate = format(dayjsDate.toDate(), `yyyy-MM-dd'T'HH:mm`)
    }
    return (
        <input
            type="datetime-local"
            id={id}
            name="dateTime"
            value={formattedDate}
            className={clsx(
                'w-full text-sm hover:bg-gray-100 border border-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md',
                className
            )}
            onChange={(event) => {
                if (!event.target.validity.valid) return
                const dt = event.target.value
                onChange(dayjs(dt))
            }}
        />
    )
}

export default DateTimePicker
