import mixpanel from 'mixpanel-browser'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import {
    useGetUserSubscriptionLazyQuery,
    useValidateUserIdentityMutation
} from '../api/client'
import Loader from '../components/Loader'
import NoActiveSubscription from '../components/subscription/NoActiveSubscription'
import { PaymentPageRedirect } from '../components/subscription/PaymentPageRedirect'
import LoadingWelcome from '../components/unauthenticated/LoadingWelcome'
import useQueryParams from '../hooks/useQueryParams'
import User from '../models/User'
import {
    canUserLikeContentState,
    canUserReplyToContentState,
    canViewIntelligencePageState,
    isPlatformAppReviewerState,
    isUserAdminState,
    userState
} from '../store/DomainStore'
import { getUserPermissions } from '../util/auth'

type Props = {
    children?: React.ReactNode
}

/**
 * This container fetches all data required from the server
 * for correct functioning of the rest of the application.
 * For example, we need the user details in the store
 * before being able to proceed with subscription
 */
function AppInitialisationContainer(props: Props) {
    const { children } = props

    const query = useQueryParams()
    const referrer = query.get('referrer')

    // See the validateUserMutation docs in Rust graphql for an explanation around why this exists.
    const [validateUserMutation] = useValidateUserIdentityMutation({
        onCompleted: (user) => {
            console.log('User identity validated')
            getUserSubscription({ variables: { referrer } })
            setCanUserReplyToContent(
                user.validateUserIdentity.canReplyToContent
            )
        }
    })

    useEffect(() => {
        validateUserMutation()
        // eslint-disable-next-line
    }, [])

    const [
        getUserSubscription,
        {
            data: subscriptionData,
            loading: subscriptionLoading,
            error: subscriptionError
        }
    ] = useGetUserSubscriptionLazyQuery()

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [user, setUser] = useRecoilState(userState)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const [isUserAdmin, setIsUserAdmin] = useRecoilState(isUserAdminState)
    const [canViewIntelligencePage, setCanViewIntelligencePage] =
        useRecoilState(canViewIntelligencePageState)
    const [canUserLikeContent, setCanUserLikeContent] = useRecoilState(
        canUserLikeContentState
    )
    const [canUserReplyToContent, setCanUserReplyToContent] = useRecoilState(
        canUserReplyToContentState
    )
    const [isPlatformAppReviewer, setPlatformAppReviewer] = useRecoilState(
        isPlatformAppReviewerState
    )

    useEffect(() => {
        if (subscriptionData) {
            const userData = subscriptionData.getUserSubscription as User
            setUser(userData)
            if (userData.username) {
                // There is a user so we need to identify them in mixpanel. This will allow us to track their actions.
                mixpanel.identify(userData.username)
            }

            setCanUserLikeContent(
                subscriptionData.getUserSubscription.canLikeContent
            )
            setCanViewIntelligencePage(
                subscriptionData.getUserSubscription.intelligencePageViewer
            )
            setCanUserReplyToContent(
                subscriptionData.getUserSubscription.canReplyToContent
            )
        }
        /* eslint-disable-next-line */
    }, [subscriptionData])

    if (subscriptionError) {
        throw new Error(
            `Unable to retrieve user details - please try again, ${subscriptionError}`
        )
    }

    useEffect(() => {
        async function setPermissions() {
            const userPermissions = await getUserPermissions()
            setIsUserAdmin(userPermissions.isAdmin)
            setPlatformAppReviewer(userPermissions.isPlatformAppReviewer)
        }
        setPermissions()
        // eslint-disable-next-line
    }, [])

    const freshUser = subscriptionData?.getUserSubscription as User

    const userAvailable = !!freshUser?.username

    /* We need to be a little careful about timings here. We don't want to
     * render the children until the user is available. And the user isn't
     * fully available until it has been set in recoil. So we need to wait
     * for that to happen before rendering the children.
     */
    let display = <Loader status="pending" />
    if (userAvailable && !subscriptionLoading) {
        display = <>{children}</>
    }

    // Possible states:
    // - User has does not have a referrer or a subscription => show welcome and email us page
    // - User has a referrer and a subscription => full access
    // - User has a referrer and does not have a subscription => show subscription page
    // Do not use routing for this.

    if (subscriptionLoading) {
        return <LoadingWelcome />
    }

    if (userAvailable && user?.shouldProvisionAccess) {
        return <>{children}</>
    }

    if (
        userAvailable &&
        !user.shouldProvisionAccess &&
        user?.referrer?.priceId &&
        !subscriptionLoading
    ) {
        return <PaymentPageRedirect />
    }

    if (
        userAvailable &&
        !user.shouldProvisionAccess &&
        !user?.referrer?.priceId
    ) {
        // Show no subscription page
        return <NoActiveSubscription />
    }

    return (
        <div className="full-screen">
            <LoadingWelcome />
        </div>
    )
}

export default AppInitialisationContainer
