import { Dialog } from '@headlessui/react'
import { useState } from 'react'
import { useAddDefaultKeywordsMutation } from '../../api/client'
import SimpleLoader from '../SimpleLoader'
import SimpleModal from '../SimpleModal'

type Props = {
    accountId: number
    onChangeKeywords: () => void
}
function AddDefaultKeywordsButton(props: Props) {
    const { accountId, onChangeKeywords } = props

    const [confirmOpen, setConfirmOpen] = useState(false)

    const [addDefaultKeywordsMutation, { loading }] =
        useAddDefaultKeywordsMutation()

    async function handleResetToDefault() {
        await addDefaultKeywordsMutation({
            variables: { accountId: accountId, reset: true },
        })

        setConfirmOpen(false)
        await onChangeKeywords()
    }

    async function handleAddDefaults() {
        await addDefaultKeywordsMutation({
            variables: { accountId: accountId, reset: false },
        })
        setConfirmOpen(false)
        await onChangeKeywords()
    }

    return (
        <div>
            <button
                className="relative flex items-stretch h-10 p-1 px-2 border-0 flex-grow focus-within:z-10 rounded-md shadow ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 "
                onClick={() => setConfirmOpen(true)}
            >
                <span>Add default keywords...</span>
            </button>

            <SimpleModal
                open={confirmOpen}
                onClose={() => {
                    setConfirmOpen(false)
                }}
            >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                        as="h3"
                        className="text-lg leading-6  text-gray-950"
                    >
                        Are you sure?
                    </Dialog.Title>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            You have a choice to either:
                            <ul className="list-disc">
                                <li>
                                    Delete all existing keywords and add only
                                    the defaults. This will remove any existing
                                    keywords that might have been added.
                                </li>
                                <li>
                                    Add the default keywords to the existing
                                    keywwords.
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="flex flex-row">
                        <div className="mt-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm  text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                                onClick={handleResetToDefault}
                            >
                                Delete existing and add defaults
                            </button>
                        </div>
                        <div className="mt-4 ml-4">
                            <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm  text-primary-900 hover:bg-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                                onClick={handleAddDefaults}
                            >
                                Add defaults to existing list
                            </button>
                        </div>

                        <div className="ml-6 mt-4">
                            <SimpleLoader loading={loading} />
                        </div>
                    </div>
                </Dialog.Panel>
            </SimpleModal>
        </div>
    )
}

export default AddDefaultKeywordsButton
