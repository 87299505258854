import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { AccountRecord, useGetAccountQuery } from '../api/client'
import SimpleLoader from '../components/SimpleLoader'
import { AccountSettings } from '../components/account/AccountSettings'
import ContentList from '../components/content/ContentList'
import { canUseBulkActions } from '../components/content/bulk-actions'
import ContentSearchBar from '../components/content/search/ContentSearchBar'
import useContentSearchParams from '../hooks/useContentQueryParams'
import { userState } from '../store/DomainStore'
import { selectedAccountState } from '../store/UIStore'
import { useTitle } from '../components/TitleProvider'

function AccountPage() {
    let { accountId: accountIdString } = useParams()
    const [account, setAccount] = useState<AccountRecord>()
    const accountId = Number(accountIdString)
    const setSelectedAccount = useSetRecoilState(selectedAccountState)
    const [user] = useRecoilState(userState)

    const {
        searchParameters,
        onSearchParameterChange,
        allSocialMediaServices
    } = useContentSearchParams()

    //
    // Get necessary data
    //
    const { data: accountData, loading: loadingAccount } = useGetAccountQuery({
        variables: { accountId: accountId },
        onError: (error: any) => {
            throw Error('Unable to load account information. ' + error.message)
        }
    })

    // This is a bit convoluted and feel free to simplify it, but it just needs
    // to handle the `undefined` cases for the account name and platform name.
    let titleAccountName: string =
        accountData?.getAccount?.accountName || 'Account'
    let titlePlatformName: string | undefined =
        accountData?.getAccount?.socialMediaServiceDisplayName || undefined
    titlePlatformName = titlePlatformName ? `(${titlePlatformName})` : ''
    useTitle(`${titleAccountName} ${titlePlatformName}`)

    useEffect(() => {
        if (accountData?.getAccount) {
            setAccount(accountData?.getAccount)
            // We need to set this to support components that need the selected account but are not
            // within the react-router sub-route. Pretty annoying - could refactor the header but this is easier.
            setSelectedAccount(accountData?.getAccount)
        }
        // Disable the eslint warning because setters are functions and don't need to be included in the dependency array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData])

    if (loadingAccount) {
        return <SimpleLoader loading={loadingAccount} />
    }

    if (account) {
        const enableBulkActions = canUseBulkActions(user, account)
        return (
            <>
                {loadingAccount ? (
                    <div className="mt-20">
                        <SimpleLoader loading={loadingAccount} />{' '}
                    </div>
                ) : null}
                <div className="pt-4 ">
                    <AccountSettings account={account} />
                </div>
                <ContentSearchBar
                    searchParameters={{
                        ...searchParameters,
                        selectedAccountIds: [account.id]
                    }}
                    onSearchParameterChange={onSearchParameterChange}
                    allSocialMediaServices={allSocialMediaServices}
                    showTopicsSearch={false}
                    showProfileSearch={false}
                    showSocialMediaServiceSearch={false}
                    showTeamSearch={false}
                    showReadStatusSearch={user.canUseReadStatus}
                    showEmotionsSearch={false}
                    account={account}
                />
                <hr></hr>
                <ContentList
                    searchParameters={{
                        ...searchParameters,
                        selectedAccountIds: [account.id]
                    }}
                    showOwningAccount={false}
                    enableBulkActions={enableBulkActions}
                ></ContentList>
            </>
        )
    }
    return <SimpleLoader loading={loadingAccount} />
}

export default AccountPage
