import { Controller, useFormContext } from 'react-hook-form'

/**
 * This component uses the react-hook-form library to render a form field.
 * It requires useForm to be called in the parent component and the control and errors passed in.
 */
function AcceptYouTubeTerms() {
    const {
        control,
        formState: { errors },
    } = useFormContext()

    return (
        <div className="mt-4">
            <Controller
                render={({ field }) => (
                    <input
                        {...field}
                        className="rounded shadow"
                        type="checkbox"
                    />
                )}
                control={control}
                name="acceptTermsCheckbox"
                rules={{
                    required: true,
                }}
            />
            <label className="ml-4">
                I accept the{' '}
                <a
                    href="https://www.youtube.com/t/terms"
                    className="text-primary-600 underline"
                    target="_blank"
                    rel="noreferrer"
                >
                    YouTube Terms of Service
                </a>
            </label>
            <br />
            <span className="text-yellow-600 text-sm">
                {
                    // @ts-ignore
                    errors.acceptTermsCheckbox &&
                        // @ts-ignore
                        errors.acceptTermsCheckbox.type === 'required' && (
                            <span>
                                Please accept the YouTube Terms of Service
                                before proceeding.
                            </span>
                        )
                }
            </span>
        </div>
    )
}

export default AcceptYouTubeTerms
