import { Description, DialogPanel, DialogTitle } from '@headlessui/react'
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable
} from '@tanstack/react-table'
import clsx from 'clsx'
import { useState } from 'react'
import {
    AccountRecord,
    NotificationConfigurationView,
    useCreateNotificationConfigurationMutation,
    useDeleteNotificationConfigurationMutation,
    useGetNotificationConfigurationForAccountQuery,
    useUpdateNotificationConfigurationMutation
} from '../../api/client'
import Button from '../Button'
import CloseButton from '../CloseButton'
import LinkButton from '../LinkButton'
import { toastError, toastSuccess } from '../Notification'
import NotificationConfigurationForm from '../notifications/NotificationConfigurationForm'
import SimpleLoader from '../SimpleLoader'
import SimpleModal from '../SimpleModal'
import { fuzzyFilter } from '../table/helpers'
import SimpleTable from '../table/SimpleTable'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'

type Props = {
    account: AccountRecord
}

const columnHelper = createColumnHelper<NotificationConfigurationView>()

function NotificationConfigurationList(props: Props) {
    const { account } = props

    // State
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [currentNotification, setCurrentNotification] =
        useState<NotificationConfigurationView | null>(null)

    // Load the notification configuration data.
    const {
        data: notificatioConfigruationData,
        loading: notificationConfigurationLoading,
        refetch
    } = useGetNotificationConfigurationForAccountQuery({
        variables: { accountId: account.id },
        // This is an interesting one - by default false one of the results of this is to set loading to true when refetch is called. Getting the loading indicators on this page right is tricky!
        notifyOnNetworkStatusChange: true
    })

    const notificationConfigurations =
        notificatioConfigruationData?.getNotificationConfigurationForAccount

    const [
        createNotificationConfiguration,
        { loading: creatingNotificationConfiguration }
    ] = useCreateNotificationConfigurationMutation()

    const [
        deleteNotificationConfiguration,
        { loading: deletingNotificationConfiguration }
    ] = useDeleteNotificationConfigurationMutation()
    const [
        updateNotificationConfiguration,
        { loading: updatingNotificationConfiguration }
    ] = useUpdateNotificationConfigurationMutation()

    const isLoading =
        notificationConfigurationLoading ||
        creatingNotificationConfiguration ||
        deletingNotificationConfiguration ||
        updatingNotificationConfiguration

    async function onDelete(notificationConfigurationId: number) {
        await deleteNotificationConfiguration({
            variables: {
                notificationConfigurationId
            },
            onCompleted: () => {
                toastSuccess('Delete successful')
            },
            onError: (error) => {
                console.error(error)
                toastError(
                    'Failed to delete notification configuration - please contact support.'
                )
            }
        })

        await refetch()
        setCurrentNotification(null)
    }

    async function onSave(
        templateId: string,
        recipientListId: string,
        id?: number
    ) {
        if (id) {
            await updateNotificationConfiguration({
                variables: {
                    notificationConfigurationId: id,
                    accountId: account.id,
                    recipientListId: recipientListId,
                    notificationTemplateId: templateId
                },
                onCompleted: () => {
                    toastSuccess('Update successful')
                },
                onError: (error) => {
                    console.error(error)
                    toastError(
                        'Failed to update notification configuration - please contact support.'
                    )
                }
            })
        } else {
            // There is no id so lets create a new notification
            await createNotificationConfiguration({
                variables: {
                    accountId: account.id,
                    recipientListId: recipientListId,
                    notificationTemplateId: templateId
                },
                onCompleted: () => {
                    toastSuccess('Create successful')
                },
                onError: (error) => {
                    console.error(error)
                    toastError(
                        'Failed to create notification configuration - please contact support.'
                    )
                }
            })
        }

        await refetch()

        setCurrentNotification(null)

        setEditModalOpen(false)
    }

    function onCancel() {
        setEditModalOpen(false)
    }

    const columns = [
        columnHelper.accessor('id', {
            header: () => 'ID',
            cell: (props) => props.getValue()
        }),
        columnHelper.accessor('recipientListId', {
            header: () => 'Recipient List ID',
            cell: (props) => (
                <div>
                    <a
                        href={`https://app.courier.com/database/lists/${props.getValue()}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-600 underline"
                    >
                        {props.getValue()}{' '}
                        <ArrowTopRightOnSquareIcon className="w-4 inline" />
                    </a>{' '}
                </div>
            )
        }),
        columnHelper.accessor('notificationTemplateId', {
            header: () => 'Template ID',
            cell: (props) => (
                <div>
                    <a
                        href={`https://app.courier.com/assets/notifications/${props.getValue()}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-600 underline"
                    >
                        {props.getValue()}{' '}
                        <ArrowTopRightOnSquareIcon className="w-4 inline" />
                    </a>{' '}
                </div>
            )
        }),
        columnHelper.display({
            id: 'actions',
            cell: (props) => (
                <div className="flex space-x-2">
                    <LinkButton
                        text="Edit"
                        onClick={() => {
                            setCurrentNotification(props.row.original)
                            setEditModalOpen(true)
                        }}
                    />
                    <LinkButton
                        text="Delete"
                        onClick={() => {
                            onDelete(props.row.original.id)
                        }}
                    />
                </div>
            )
        })
    ]

    const table = useReactTable({
        data: notificationConfigurations || [],
        columns,

        getCoreRowModel: getCoreRowModel(),
        filterFns: {
            fuzzy: fuzzyFilter
        }
    })

    return (
        <div>
            {isLoading && <SimpleLoader loading={true} />}

            {!isLoading && (
                <>
                    {notificationConfigurations && (
                        <SimpleTable table={table} />
                    )}

                    <Button
                        text={'Create new notification'}
                        primary={true}
                        onClick={() => {
                            setEditModalOpen(true)
                        }}
                    />
                    <SimpleModal
                        open={deleteModalOpen}
                        onClose={function (): void {
                            setDeleteModalOpen(false)
                        }}
                        children={
                            <DialogPanel
                                className={clsx(
                                    'max-w-lg space-y-4 border bg-white p-12 ',
                                    'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300'
                                )}
                            >
                                <DialogTitle
                                    as="h1"
                                    className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                                >
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="mt-2 ml-3">
                                            {currentNotification
                                                ? 'Edit notification'
                                                : 'Create notification'}
                                        </div>

                                        <CloseButton
                                            onClick={() => {
                                                setEditModalOpen(false)
                                            }}
                                        />
                                    </div>
                                </DialogTitle>
                                <Description>
                                    This data must match the Recipient List ID
                                    and Template ID from{' '}
                                    <a
                                        href="https://app.courier.com/"
                                        className="underline text-primary-600"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Courier
                                    </a>
                                    .
                                </Description>

                                <DialogPanel>
                                    <div className="flex flex-col min-w-96 w-96">
                                        <NotificationConfigurationForm
                                            notificationConfiguration={
                                                currentNotification
                                            }
                                            onSave={onSave}
                                            onCancel={onCancel}
                                            saving={
                                                creatingNotificationConfiguration ||
                                                updatingNotificationConfiguration
                                            }
                                        />
                                    </div>
                                </DialogPanel>
                            </DialogPanel>
                        }
                    />
                    <SimpleModal
                        open={editModalOpen}
                        onClose={function (): void {
                            setEditModalOpen(false)
                        }}
                        children={
                            <DialogPanel
                                className={clsx(
                                    'max-w-lg space-y-4 border bg-white p-12 ',
                                    'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300'
                                )}
                            >
                                <DialogTitle
                                    as="h1"
                                    className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                                >
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="mt-2 ml-3">
                                            {currentNotification
                                                ? 'Edit notification'
                                                : 'Create notification'}
                                        </div>

                                        <CloseButton
                                            onClick={() => {
                                                setEditModalOpen(false)
                                            }}
                                        />
                                    </div>
                                </DialogTitle>
                                <Description>
                                    This data must match the Recipient List ID
                                    and Template ID from{' '}
                                    <a
                                        href="https://app.courier.com/"
                                        className="underline text-primary-600"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Courier
                                    </a>
                                    .
                                </Description>

                                <DialogPanel>
                                    <div className="flex flex-col min-w-96 w-96">
                                        <NotificationConfigurationForm
                                            notificationConfiguration={
                                                currentNotification
                                            }
                                            onSave={onSave}
                                            onCancel={onCancel}
                                            saving={
                                                creatingNotificationConfiguration ||
                                                updatingNotificationConfiguration
                                            }
                                        />
                                    </div>
                                </DialogPanel>
                            </DialogPanel>
                        }
                    />
                </>
            )}
        </div>
    )
}

export default NotificationConfigurationList
