import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useGetSocialMediaServicesQuery, useGetTeamsQuery } from '../api/client'
import WarningCallout from '../components/callout/WarningCallout'
import { canUseBulkActions } from '../components/content/bulk-actions'
import ContentList from '../components/content/ContentList'
import ContentSearchBar from '../components/content/search/ContentSearchBar'
import { toastError } from '../components/Notification'
import Heatmap from '../components/visualisations/Heatmap'
import LineChart from '../components/visualisations/LineChart'
import useContentSearchParams from '../hooks/useContentQueryParams'
import { isUserAdminState, userState } from '../store/DomainStore'

export const EMOTIONS_LIST = [
    'joy',
    'approval',
    'caring',
    'relief',
    'desire',
    'admiration',
    'optimism',
    'love',
    'excitement',
    'amusement',
    'surprise',
    'gratitude',
    'pride',
    'curiosity',
    'neutral',
    'realization',
    'confusion',
    'nervousness',
    'disappointment',
    'sadness',
    'annoyance',
    'disapproval',
    'embarrassment',
    'anger',
    'remorse',
    'disgust',
    'grief',
    'fear'
].reverse()

export const EMOTION_THRESHOLD = 0.2

export const TOPICS_LIST = [
    'food',
    'service',
    'venue',
    'atmosphere',
    'facilities',
    'events'
]

export const TOPICS_THRESHOLD = 0.5

export default function IntelligencePage() {
    const {
        searchParameters,
        onSearchParameterChange,
        allSocialMediaServices,
        setAllSocialMediaServices
    } = useContentSearchParams()

    const [admin] = useRecoilState(isUserAdminState)
    const [user] = useRecoilState(userState)

    const { data: getTeamsData } = useGetTeamsQuery({
        onError: () => {
            toastError('Error fetching user teams. Please contact support.')
        }
    })

    const hasMoreThanOneTeam: boolean = !!(
        getTeamsData?.getTeams?.length && getTeamsData?.getTeams?.length > 1
    )

    // The selected social media services
    const { data: socialMediaServicesData } = useGetSocialMediaServicesQuery({})

    useEffect(() => {
        if (socialMediaServicesData?.getSocialMediaServices) {
            setAllSocialMediaServices(
                socialMediaServicesData?.getSocialMediaServices
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialMediaServicesData?.getSocialMediaServices])

    const hasSelectedTeam = searchParameters.selectedTeams.length > 0
    const hasSelectedProfile = searchParameters.selectedAccountIds.length > 0
    const showSlowLoadingWarning =
        admin && !hasSelectedTeam && !hasSelectedProfile
    const enableBulkActions = canUseBulkActions(user, undefined)

    // We want to show the Team drop down only if it will be useful to users. Which means they are either an admin or they are part of more than one team.
    const showTeamSearch = admin || hasMoreThanOneTeam

    return (
        <>
            <ContentSearchBar
                searchParameters={searchParameters}
                onSearchParameterChange={onSearchParameterChange}
                allSocialMediaServices={allSocialMediaServices}
                showProfileSearch={true}
                showSocialMediaServiceSearch={true}
                showTopicsSearch={true}
                showTeamSearch={showTeamSearch}
                showEmotionsSearch={true}
                showReadStatusSearch={user.canUseReadStatus}
            />

            <div className="flex flex-col" style={{ width: 1200 }}>
                {showSlowLoadingWarning && (
                    <div className="mx-4">
                        <WarningCallout>
                            This query is very broad and may result in an error
                            or take a long time to complete.{' '}
                            <strong>
                                Select a team or a profile to avoid this.
                            </strong>
                        </WarningCallout>
                    </div>
                )}
                <h2 className="text-lg font-bold text-gray-400 ml-6 mb-0 mt-4">
                    Topics by Sentiment
                </h2>

                <Heatmap
                    width={1200}
                    height={300}
                    searchParameters={searchParameters}
                    onSearchParameterChange={onSearchParameterChange}
                    emotions={EMOTIONS_LIST}
                    topics={TOPICS_LIST}
                ></Heatmap>

                <h2 className="text-lg font-bold text-gray-400 ml-6  mb-2 mt-2">
                    Comment Volumes by Date
                </h2>

                <LineChart
                    width={1200}
                    height={175}
                    searchParameters={searchParameters}
                    onSearchParameterChange={onSearchParameterChange}
                />
            </div>
            <div style={{ minHeight: '600px' }} className="stretchy">
                <ContentList
                    searchParameters={searchParameters}
                    showOwningAccount={false}
                    enableBulkActions={enableBulkActions}
                ></ContentList>
            </div>
        </>
    )
}
