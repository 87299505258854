import clsx from 'clsx'
import * as React from 'react'

type Props = {
    text: string | React.ReactNode
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

function LinkButton(props: Props) {
    const { text, onClick } = props

    return (
        <button
            className={clsx(
                'ml-4 px-4 py-2 text-primary-600 ',
                'hover:text-primary-900',
                'rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500'
            )}
            onClick={onClick}
        >
            {text}
        </button>
    )
}

export default LinkButton
