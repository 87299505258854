import { useRecoilState } from 'recoil'
import { fetchTimePeriodState } from '../store/UIStore'
import ArwenListBox, { ArwenListBoxItem } from './inputs/ArwenListBox'


/**
 * @deprecated 2025-01-14: This time period dropdown should be replaced with DateTimePickerContainer.tsx. 
 * It supposes durations passed to the backend. 
 */
function FetchTimePeriodDropDown() {
    const [fetchTimePeriod, setFetchTimePeriod] =
        useRecoilState(fetchTimePeriodState)

    const options: ArwenListBoxItem[] = [
        { id: String(1), name: 'For the last day' },
        { id: String(7), name: 'For the last 7 days' },
        { id: String(14), name: 'For the last 14 days' },
        { id: String(30), name: 'For the last 30 days' },
        { id: String(30 * 3), name: 'For the last 3 months' },
    ]

    const value: ArwenListBoxItem =
        options.find((value) => {
            return value.id === `${fetchTimePeriod}`
        }) || options[3]

    return (
        <div className=" text-gray-950 flex ">
            <ArwenListBox
                onSelect={(option: ArwenListBoxItem) => {
                    const days = option.id
                    setFetchTimePeriod(Number(days))
                }}
                options={options}
                value={value}
                widthClass="w-42"
            />
        </div>
    )
}
export default FetchTimePeriodDropDown
