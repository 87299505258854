import React from 'react'
import ContentFeedList from '../components/feeds/ContentFeedList'
import AccountList from '../components/account/AccountList'
import { useTitle } from '../components/TitleProvider'
import { useRecoilState } from 'recoil'
import { userState } from '../store/DomainStore'

const HomePage: React.FC = () => {
    useTitle('Home')
    const [user] = useRecoilState(userState)
    return (
        <>
            {user.feedsEnabled && (
                <>
                    <div className="m-4 my-10 ml-10">
                        <ContentFeedList />
                    </div>
                    <hr />
                </>
            )}
            <AccountList />
        </>
    )
}

export default HomePage
