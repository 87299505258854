import dayjs from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import { classNames } from '../../../util/classNames'

dayjs.extend(duration)

// If you're not sure what the duration codes here mean then study this because it's worth
// being able to read ISO8601 durations:
// +------------------------+----------------------+-------------------------------------+
// |     Duration Type      |   ISO 8601 Format    |            Description             |
// +------------------------+----------------------+-------------------------------------+
// | 1 Day                  | P1D                  | Represents 1 day.                  |
// | 2 Weeks                | P2W                  | Represents 2 weeks (14 days).      |
// | 3 Months               | P3M                  | Represents 3 months.               |
// | 1 Year                 | P1Y                  | Represents 1 year.                 |
// | 1 Year and 2 Months    | P1Y2M                | Represents 1 year and 2 months.    |
// | 1 Day and 12 Hours     | P1DT12H              | Represents 1 day and 12 hours.     |
// | 5 Hours and 30 Minutes | PT5H30M              | Represents 5 hours and 30 minutes. |
// | 45 Minutes             | PT45M                | Represents 45 minutes.             |
// | 30 Seconds             | PT30S                | Represents 30 seconds.             |
// | 2 Days, 4 Hours,       | P2DT4H20M            | Represents 2 days, 4 hours, and    |
// | and 20 Minutes         |                      | 20 minutes.                        |
// +------------------------+----------------------+-------------------------------------+
export const LAST_HOUR = dayjs.duration('PT1H')
export const LAST_12_HOURS = dayjs.duration('PT12H')
export const LAST_DAY = dayjs.duration('P1D')
export const LAST_7_DAYS = dayjs.duration('P7D')
export const LAST_14_DAYS = dayjs.duration('P14D')
export const LAST_30_DAYS = dayjs.duration('P30D')

type Props = {
    duration: Duration | undefined
    onChange: (duration: Duration) => void
}

const VALID_TIME_PERIODS_DISPLAY = [
    { duration: LAST_HOUR, display: '1h', caption: 'Last hour' },
    {
        duration: LAST_12_HOURS,
        display: '12h',
        caption: 'Last 12 hours'
    },
    { duration: LAST_DAY, display: '1d', caption: 'Last day' },
    { duration: LAST_7_DAYS, display: '7d', caption: 'Last 7 days' },
    { duration: LAST_14_DAYS, display: '14d', caption: 'Last 14 days' },
    { duration: LAST_30_DAYS, display: '30d', caption: 'Last 30 days' }
]

function RelativeTimePeriod(props: Props) {
    const { duration, onChange } = props

    function handleClick(duration: Duration) {
        onChange(duration)
    }

    return (
        <div className="text-center text-gray-950 items-center flex">
            {VALID_TIME_PERIODS_DISPLAY.map((period) => {
                let roundedClasses = 'rounded-md'

                let classes =
                    'relative font-medium inline-flex items-center m-1 px-2 py-2 border-0 bg-white text-sm text-gray-800 hover:bg-gray-100 hover:border-gray-400 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                if (duration?.toISOString() === period.duration.toISOString()) {
                    classes =
                        'relative font-semibold inline-flex items-center m-1 px-2 py-2 bg-gray-200 border-0 text-sm hover:bg-gray-100 hover:border-gray-400 text-gray-800 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary '
                }

                return (
                    <button
                        key={period.display}
                        className={classNames(roundedClasses, classes)}
                        onClick={() => handleClick(period.duration)}
                    >
                        {period.display}
                    </button>
                )
            })}
        </div>
    )
}
export default RelativeTimePeriod
