import { Field, Input, Label } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { object, string } from 'yup'
import {
    ContentFeedCreate,
    ContentFeedUpdate,
    ContentFeedView,
    useCreateContentFeedMutation,
    useUpdateContentFeedMutation
} from '../../api/client'
import Button from '../Button'
import SearchParameters, {
    mapToSearchParamsForContentOutcome
} from '../content/search/searchParameters'

interface FormData {
    description?: string
    name: string
}

type Props = {
    onCompleted: (newContentFeedId: number, isNew: boolean) => void
    searchParameters: SearchParameters
    contentFeed?: ContentFeedView
}

const validationSchema = object({
    name: string().required('Name is required'),
    description: string().optional()
})

/**
 * Modal that allows the user to remove content from the spam model
 */
export default function ContentFeedEditForm({
    onCompleted,
    contentFeed,
    searchParameters
}: Props) {
    const [createContentFeedMutation, { loading: createLoading }] =
        useCreateContentFeedMutation()

    const [updateContentFeedMutation, { loading: updateLoading }] =
        useUpdateContentFeedMutation()

    const form = useForm<FormData>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema)
    })

    const onSave = async (data: FormData) => {
        // We're going to map between models here, because it's common for update and create
        const mappedSearchParameters =
            mapToSearchParamsForContentOutcome(searchParameters)

        if (contentFeed) {
            const contentFeedUpdate: ContentFeedUpdate = {
                id: contentFeed.id,
                displayName: data.name,
                description: data.description,
                searchParameters: mappedSearchParameters
            }
            updateContentFeedMutation({
                variables: {
                    contentFeedUpdate
                },
                onCompleted: (data) => {
                    onCompleted(data.updateContentFeed.id, false)
                },
                onError: (error) => {
                    toast.error(`Failed to update content feed: ${error}`)
                }
            })
        }
    }

    const onSaveAsNew = async (data: FormData) => {
        const mappedSearchParameters =
            mapToSearchParamsForContentOutcome(searchParameters)

        const contentFeedNew: ContentFeedCreate = {
            displayName: data.name,
            description: data.description,
            searchParameters: mappedSearchParameters
        }
        createContentFeedMutation({
            variables: { contentFeedNew },
            onCompleted: (data) => {
                return onCompleted(data.createContentFeed.id, true)
            },
            onError: (error) => {
                return toast.error(`Failed to create content feed: ${error}`)
            }
        })
    }

    return (
        <div className="flex flex-col mb-3 prose prose-stone">
            <FormProvider {...form}>
                <form
                    onSubmit={form.handleSubmit(onSave)}
                    className="divide-gray-200"
                >
                    <p>
                        Once saved this will appear on your{' '}
                        <Link to="/">homepage</Link> with the following name.
                    </p>
                    <Field className="mt-6">
                        <Label className="block leading-6 text-gray-700">
                            Name
                        </Label>
                        <Input
                            type="text"
                            defaultValue={contentFeed?.displayName}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                            placeholder="A short name"
                            {...form.register('name')}
                        />
                        {form.formState.errors.name && (
                            <span className="text-yellow-600 text-sm">
                                {form.formState.errors.name.message}
                            </span>
                        )}
                    </Field>

                    <Field className="mt-6">
                        <Label className="block leading-6 text-gray-700">
                            Description
                        </Label>
                        <Input
                            defaultValue={contentFeed?.description ?? ''}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                            placeholder="A longer description of the report and its purpose."
                            {...form.register('description')}
                        />
                        {form.formState.errors.description && (
                            <span className="text-yellow-600 text-sm">
                                {form.formState.errors.description.message}
                            </span>
                        )}
                    </Field>
                    <div className="mt-10 space-x-4">
                        {contentFeed ? (
                            <Button
                                primary
                                text={'Save'}
                                type="submit"
                                size="large"
                                disabled={createLoading || updateLoading}
                                loading={updateLoading ?? false}
                            />
                        ) : undefined}
                        <Button
                            primary
                            text="Save as new content feed"
                            type="button"
                            size="large"
                            onClick={form.handleSubmit(onSaveAsNew)}
                            disabled={createLoading || updateLoading}
                            loading={createLoading ?? false}
                        />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}
