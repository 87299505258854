import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/solid'
import { Table } from '@tanstack/react-table'

type Props = {
    table: Table<any>
}

/**
 * This component is designed to be used with the tanstack/react-table library.
 */
export default function SimplePagination(props: Props) {
    const { table } = props

    function getEnabledStatusClasses(enabled: boolean): string {
        if (enabled) {
            return 'text-gray-950 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
        } else {
        }
        return 'text-gray-300 cursor-not-allowed'
    }

    return (
        <div className="flex items-center">
            <div className="flex items-center gap-2 flex-wrap">
                <div className="flex items-center">
                    <button
                        className={`relative inline-flex items-center rounded-l-md shadow px-2 py-2 ring-1 ring-inset ring-gray-300   ${getEnabledStatusClasses(
                            table.getCanPreviousPage()
                        )}`}
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronDoubleLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                    <button
                        className={`relative inline-flex -ml-px items-center px-2 py-2 shadow ring-1 ring-inset ring-gray-300   ${getEnabledStatusClasses(
                            table.getCanPreviousPage()
                        )}`}
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                    <button
                        className={`relative inline-flex -ml-px items-center px-2 py-2 shadow ring-1 ring-inset ring-gray-300   ${getEnabledStatusClasses(
                            table.getCanNextPage()
                        )}`}
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                    <button
                        className={`relative inline-flex -ml-px rounded-r-md shadow items-center px-2 py-2  ring-1 ring-inset ring-gray-300   ${getEnabledStatusClasses(
                            table.getCanNextPage()
                        )}`}
                        onClick={() =>
                            table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                    >
                        <span className="sr-only">Next</span>
                        <ChevronDoubleRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </button>
                </div>

                <span className="flex items-center gap-1">
                    <div>Page</div>
                    <strong>
                        {table.getState().pagination.pageIndex + 1} of{' '}
                        {table.getPageCount()}
                    </strong>
                </span>

                <span className="flex items-center gap-1">
                    | Go to page:
                    <input
                        type="number"
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value
                                ? Number(e.target.value) - 1
                                : 0
                            table.setPageIndex(page)
                        }}
                        className="py-1.5 px-2 w-16 rounded-md border-0 h-9 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    />
                </span>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                        table.setPageSize(Number(e.target.value))
                    }}
                    className="block  rounded-md border-0 py-1.5 text-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                >
                    {[10, 20, 50, 100, 400].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    )
}
