import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Field,
    Label,
    Transition,
} from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useState } from 'react'
import Spinner from '../Spinner'
import ArwenComboBoxOption from './ArwenComboBoxOption'
import { ComboboxMenuOption } from './ComboboxMenuOption'

type Props = {
    label: String
    options: ComboboxMenuOption[]
    value?: ComboboxMenuOption
    onChange: (option: ComboboxMenuOption) => void
    onQueryChange: (query: string) => void
    isLoading: boolean
    isSaving?: boolean
    canCreate: boolean
}

/**
 * Rather proud of this. Its a ComboBox that can be used to select an item from a list
 * of options and create a new item if it doesn't exist.
 *
 * For example to search for a team and create a new team if necessary.
 * See documentation here https://headlessui.com/react/combobox
 */
export default function ComboboxMenu(props: Props) {
    const {
        label,
        options,
        value,
        onChange,
        onQueryChange,
        isLoading,
        isSaving,
        canCreate,
    } = props
    // Keep internal track of the value in the input box that the user is typing in.
    const [query, setQuery] = useState('')

    const noResults = options.length === 0 && !isLoading

    return (
        <Field disabled={isSaving}>
            <Combobox value={value} onChange={onChange} multiple={false}>
                {({ open }) => (
                    <>
                        <Label className="block leading-6 text-gray-700">
                            {label}
                        </Label>
                        <div className="relative mt-2">
                            <ComboboxInput
                                className={`disabled w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-950 shadow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6`}
                                onChange={(event) => {
                                    onQueryChange(event.target.value)
                                    setQuery(event.target.value)
                                }}
                                displayValue={(option: ComboboxMenuOption) =>
                                    (option && option.name) || ''
                                }
                            />
                            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </ComboboxButton>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <ComboboxOptions className="list-none px-0 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {noResults && !canCreate ? (
                                        // There are no results and this component isn't configured to create new items
                                        <ComboboxOption
                                            value="No results"
                                            disabled={true}
                                        >
                                            <div className="text-gray-500 pl-3">
                                                No results
                                            </div>
                                        </ComboboxOption>
                                    ) : null}
                                    {noResults && canCreate ? (
                                        // The option has not been found, present an option to create it.
                                        <ComboboxOption
                                            value={{ id: null, name: query }}
                                        >
                                            <div className=" bg-primary text-white relative  cursor-default select-none py-2 pl-3 pr-9">
                                                Create "{query}"
                                            </div>
                                        </ComboboxOption>
                                    ) : null}
                                    {isLoading ? (
                                        <ComboboxOption
                                            value="Loading"
                                            disabled={true}
                                        >
                                            <div className="pl-3 text-primary">
                                                <Spinner size={4} />
                                            </div>
                                        </ComboboxOption>
                                    ) : null}
                                    {options.map((option) => (
                                        <ComboboxOption
                                            key={option.id}
                                            value={option}
                                        >
                                            {({ focus, selected }) => (
                                                <ArwenComboBoxOption
                                                    option={option}
                                                    selected={selected}
                                                    focus={focus}
                                                />
                                            )}
                                        </ComboboxOption>
                                    ))}
                                </ComboboxOptions>
                            </Transition>
                        </div>
                    </>
                )}
            </Combobox>
        </Field>
    )
}
