import { SubmitHandler, useForm } from 'react-hook-form'
import {
    TeamView,
    useCreateTeamMutation,
    useEditTeamMutation,
} from '../../api/client'
import { useNavigate } from 'react-router-dom'
import { toastError, toastSuccess } from '../Notification'

interface IFormInput {
    name: string
}

type Props = {
    team?: TeamView
}

/**
 * This page uses the react-hook-form to create a new team.
 * https://www.react-hook-form.com/get-started/
 */
function TeamDetailsForm(props: Props) {
    const { team } = props
    let navigate = useNavigate()

    const [createTeamMutation, { loading: createTeamLoading }] =
        useCreateTeamMutation()

    const [editTeamMutation, { loading: editTeamLoading }] =
        useEditTeamMutation()

    const values = team ? team : undefined
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInput>({
        values,
    })

    // Are we creating a new team or editing an existing one?
    const isCreateMode = !!!team

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        if (isCreateMode) {
            await createTeamMutation({
                variables: { teamName: data.name },
                onCompleted: () => {
                    toastSuccess(`Team ${data.name} created. `)
                    navigate(-1)
                },
                onError: (error) => {
                    console.error(error)
                    toastError(`Unable to create a team. ${error.message}`)
                },
            })
        } else {
            await editTeamMutation({
                variables: { teamId: team.id, name: data.name },
                onCompleted: () => {
                    toastSuccess(`Team ${data.name} edited. `)
                    navigate(-1)
                },
                onError: (error) => {
                    console.error(error)
                    toastError(`Unable to edit a team. ${error.message}`)
                },
            })
        }
    }

    return (
        <div className="m-10 prose prose-stone">
            <h2 className="text-gray-950 initial">Create a new team</h2>
            <p className="text-gray-700">
                Please ask engineering to add and remove users from this team.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col ">
                    <label
                        htmlFor="name"
                        className="block  leading-6 text-gray-700"
                    >
                        Team name
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            id="name"
                            {...register('name', {
                                required: true,
                                minLength: 3,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-950 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:leading-6"
                        />
                    </div>
                    {errors.name?.type === 'required' && (
                        <p role="alert">Team name is required</p>
                    )}
                    <div className="items-center flex flex-row mt-6">
                        {createTeamLoading || editTeamLoading ? (
                            <div>Saving...</div>
                        ) : (
                            <input
                                type="submit"
                                className="text-base inline-flex items-center px-3 py-2 border border-transparent leading-4  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow-sm text-white bg-primary hover:bg-primary-700 cursor-pointer"
                            />
                        )}

                        <button
                            type="reset"
                            onClick={() => {
                                navigate(-1)
                            }}
                            className="py-2 px-6 underline"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default TeamDetailsForm
