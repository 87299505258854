import { useEffect, useState } from 'react'
import { ContentFeedView, useGetMyContentFeedsQuery } from '../../api/client'
import { toastError } from '../Notification'
import ContentFeedCard from './ContentFeedCard'
import ContentFeedLoadingSkeleton from './ContentFeedLoadingSkeleton'
import { Link } from 'react-router-dom'

/**
 * Displays a list of the user's content feeds as cards, with a title.
 */
export default function ContentFeedList() {
    const { data, loading, refetch } = useGetMyContentFeedsQuery({
        fetchPolicy: 'network-only',
        onError: (error) => {
            toastError(error.message)
        }
    })

    // We want to store local state because it lets us remove the feed from the list faster
    const [feeds, setFeeds] = useState<ContentFeedView[]>([])
    useEffect(() => {
        if (data?.getMyContentFeeds) {
            setFeeds(data.getMyContentFeeds)
        }
    }, [data])
    const handleOnDelete = (id: number) => {
        setFeeds((prevFeeds) => prevFeeds.filter((feed) => feed.id !== id))
        refetch()
    }

    return (
        <div>
            <div className="mb-4 prose prose-stone prose-lg">
                <h2>Your feeds</h2>
            </div>
            {loading ? (
                <div className="flex flex-row gap-4">
                    <ContentFeedLoadingSkeleton />
                    <ContentFeedLoadingSkeleton />
                    <ContentFeedLoadingSkeleton />
                </div>
            ) : (
                <div className="flex flex-wrap gap-8 mt-8  ">
                    {feeds.length === 0 && (
                        <div className="ml-8 text-gray-900 prose prose-stone ">
                            <p className="my-2">
                                You don’t have any feeds yet. To create a feed:
                            </p>
                            <ul className="my-0">
                                <li className="">
                                    <Link
                                        to={'/content-search/'}
                                        className="text-primary-600 underline"
                                    >
                                        search for the content you are
                                        interested in
                                    </Link>{' '}
                                    and{' '}
                                </li>
                                <li className="">
                                    click the Save button to save that search as
                                    a feed.
                                </li>
                            </ul>
                            <p className="my-0">
                                Please contact{' '}
                                <a
                                    href="mailto:support@arwen.ai"
                                    className="text-primary-600 underline"
                                >
                                    support@arwen.ai
                                </a>{' '}
                                if you have any questions.
                            </p>
                        </div>
                    )}
                    {feeds.map((feed) => (
                        <ContentFeedCard
                            key={feed.id}
                            contentFeed={feed}
                            onDeleted={handleOnDelete}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
