import { DialogPanel, DialogTitle } from '@headlessui/react'
import { clsx } from 'clsx'
import { useState } from 'react'
import CloseButton from '../CloseButton'
import SimpleModal from '../SimpleModal'
import ContentFeedEditForm from './ContentFeedEditForm'
import SearchParameters from '../content/search/searchParameters'
import { useNavigate } from 'react-router-dom'
import { ContentFeedView } from '../../api/client'
import { toastSuccess } from '../Notification'

type Props = {
    searchParameters: SearchParameters
    contentFeed?: ContentFeedView
}

/**
 * Modal that allows the user to remove content from the spam model
 */
export default function ContentFeedEditDialog({
    searchParameters,
    contentFeed
}: Props) {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    return (
        <div className="prose prose-stone">
            <button
                onClick={() => setOpen(true)}
                className={
                    'relative inline-flex items-center space-x-2 px-4 py-2 text-sm  rounded-md shadow text-gray-950 bg-white border border-gray-300 hover:border-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary'
                }
            >
                Save...
            </button>
            <SimpleModal
                open={open}
                onClose={() => {
                    setOpen(false)
                }}
            >
                <DialogPanel
                    className={clsx(
                        'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300',
                        'min-w-min max-w-4xl sm:w-fit'
                    )}
                >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 w-108">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-4 text-center sm:mt-0 sm:text-left">
                                <DialogTitle
                                    as="h1"
                                    className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                                >
                                    <div className="flex flex-row justify-between w-full">
                                        <div className="mt-2 ml-3">
                                            Save your search as a Feed
                                        </div>

                                        <CloseButton
                                            onClick={() => {
                                                setOpen(false)
                                            }}
                                        />
                                    </div>
                                </DialogTitle>
                                <DialogPanel>
                                    <ContentFeedEditForm
                                        searchParameters={searchParameters}
                                        contentFeed={contentFeed}
                                        onCompleted={(
                                            newContentFeedId: number,
                                            isNew: boolean
                                        ) => {
                                            // Dismiss the dialog
                                            setOpen(false)
                                            // We're only going to re-direct if we're creating a new feed...
                                            if (isNew) {
                                                navigate(
                                                    `/content-search/feed/${newContentFeedId}`
                                                )
                                                toastSuccess('Feed created')
                                            } else {
                                                // ... but if we're saving then we'll only display a toast
                                                toastSuccess('Feed saved')
                                            }
                                        }}
                                    />
                                </DialogPanel>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </SimpleModal>
        </div>
    )
}
