import { RankingInfo } from '@tanstack/match-sorter-utils'
import {
    FilterFn,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import { formatISO9075, parseISO } from 'date-fns'

import { TrashIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
    KeywordView,
    useDeleteKeywordMutation,
    useGetKeywordsForAccountQuery
} from '../../api/client'
import Spinner from '../../components/Spinner'
import AddDefaultKeywordsButton from '../../components/moderation/AddDefaultKeywordsButton'
import SimplePagination from '../../components/table/SimplePagination'
import SimpleTable from '../../components/table/SimpleTable'
import { fuzzyFilter, fuzzySort } from '../../components/table/helpers'
import SearchInput from '../../components/SearchInput'
import { toastError } from '../../components/Notification'

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const columnHelper = createColumnHelper<KeywordView>()

function KeywordListPage() {
    let { accountId: accountIdString } = useParams()
    const accountId = Number(accountIdString)
    const location = useLocation()

    const [globalFilter, setGlobalFilter] = useState('')
    const [data, setData] = useState<KeywordView[]>([])

    const {
        data: getKeywordsData,
        loading: getKeywordsLoading,
        refetch: refetchKeywords
    } = useGetKeywordsForAccountQuery({
        variables: { params: { accountId: accountId } }
    })

    const [deleteKeywordMutation, { loading: deleteKeywordLoading }] =
        useDeleteKeywordMutation({
            onError: (error) => {
                toastError(error.message)
            }
        })

    const handleKeywordDefaultsAdded = async () => {
        await refetchKeywords()
    }

    // // When the location changes, e.g. navigating back from creating a new team, then we should refetch.
    useEffect(
        () => {
            refetchKeywords()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    )

    function DeleteCell(info: any) {
        const keyword = info.row.original as KeywordView
        return (
            <TrashIcon
                className="h-5 w-5 text-primary-500 hover:text-primary-700 cursor-pointer"
                onClick={async () => {
                    await deleteKeywordMutation({
                        variables: { keywordId: keyword.id }
                    })
                    await refetchKeywords()
                }}
            />
        )
    }

    const columns = [
        columnHelper.accessor('id', {
            header: () => 'Id',
            cell: (info) => info.getValue()
        }),
        columnHelper.accessor('keyword', {
            header: () => 'Keyword',
            cell: (info) => (
                <span className="whitespace-pre-wrap">"{info.getValue()}"</span>
            ),
            filterFn: 'fuzzy',
            sortingFn: fuzzySort
        }),
        columnHelper.accessor('regex', {
            header: () => 'Regex',
            cell: (info) => (
                <input
                    type="checkbox"
                    className="checkbox opacity-50"
                    checked={info.getValue()}
                    disabled={true}
                />
            )
        }),
        columnHelper.accessor('allowableRegex', {
            header: () => 'Exceptions',
            cell: (info) => (
                <span className="whitespace-pre-wrap">"{info.getValue()}"</span>
            )
        }),
        columnHelper.accessor('description', {
            header: () => 'Description',
            cell: (info) => info.getValue()
        }),
        columnHelper.accessor('classifierDetailsDisplayName', {
            header: () => 'Type',
            cell: (info) => info.getValue(),
            filterFn: 'fuzzy',
            sortingFn: fuzzySort
        }),
        columnHelper.accessor('createdAt', {
            header: () => 'Created',
            cell: (info) => (
                <span>{formatISO9075(parseISO(info.getValue()))}</span>
            ),
            sortingFn: fuzzySort
        }),
        columnHelper.display({
            id: 'delete',
            cell: (props) => <DeleteCell row={props.row} />
        })
    ]

    useEffect(() => {
        if (getKeywordsData?.getKeywordsForAccount) {
            setData(getKeywordsData?.getKeywordsForAccount)
        }
    }, [getKeywordsData])

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            globalFilter
        },
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel()
    })

    return (
        <>
            <div className="m-10 prose prose-stone ">
                <h2 className="text-gray-950 initial">
                    Keyword management page
                </h2>
                <Link
                    to={`/account/${accountId}/settings`}
                    className="text-primary-600 underline"
                >
                    Back to settings
                </Link>
                <p>This page lets you create and delete keywords.</p>

                <div className="flex flex-row items-baseline">
                    <SearchInput
                        keyword={globalFilter ?? ''}
                        onKeywordChange={(value) =>
                            setGlobalFilter(String(value))
                        }
                        onSearch={(value) => setGlobalFilter(String(value))}
                    />

                    <div>
                        <a
                            href={`#/account/${accountId}/keywords/create`}
                            className="text-primary-600 underline mx-4 text-md"
                        >
                            Create Keyword
                        </a>
                    </div>
                    <div>
                        {(getKeywordsLoading || deleteKeywordLoading) && (
                            <Spinner size={6} />
                        )}
                    </div>

                    <AddDefaultKeywordsButton
                        accountId={accountId}
                        onChangeKeywords={handleKeywordDefaultsAdded}
                    />
                </div>
            </div>
            <div className="m-10 prose prose-stone max-w-none ">
                <SimpleTable table={table} />

                <SimplePagination table={table} />
            </div>
        </>
    )
}

export default KeywordListPage
