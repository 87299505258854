import duration, { Duration } from 'dayjs/plugin/duration'
import dayjs, { Dayjs } from 'dayjs'
import {
    AccountRecord,
    AdsOrOrganicFilterView,
    ContentSearchParamsView,
    InputMaybe,
    ReadFilterView,
    SearchParamsForContentOutcome,
    SocialMediaService
} from '../../../api/client'
import { ClassifierSearchParam } from '../../../models/ClassifierSearchParam'
import { AdOrOrganicFilterOption } from '../../AdOrOrganicComboBox'

dayjs.extend(duration)

type SearchParameters = {
    // Mandatory
    keyword: string
    moderation: string
    selectedAccountIds: number[]
    selectedClassifiers: ClassifierSearchParam[]
    selectedEmotions: string[]
    selectedGeneralClassifiers: string[]
    selectedSocialMediaServices: SocialMediaService[]
    selectedTeams: number[]
    selectedTopics: string[]
    // Optional
    account?: AccountRecord
    adOrOrganic?: AdOrOrganicFilterOption
    contentFeedId?: number
    contentIds?: number[]
    endDateTime?: Dayjs
    owned?: boolean
    readReceipt?: ReadFilterView
    startDateTime?: Dayjs
    timeDuration?: Duration
    selectedReplies?: string
    selectedSuggestedReplies?: string
}
export default SearchParameters

export function mapAdOrOrganicFilterOption(
    option?: AdOrOrganicFilterOption
): AdsOrOrganicFilterView | undefined {
    switch (option) {
        case AdOrOrganicFilterOption.Ads:
            return AdsOrOrganicFilterView.Ads
        case AdOrOrganicFilterOption.Organic:
            return AdsOrOrganicFilterView.Organic
        case AdOrOrganicFilterOption.Both:
            return AdsOrOrganicFilterView.Both
        default:
            return undefined
    }
}

export function mapAdOrOrganicFilterView(
    option?: AdsOrOrganicFilterView
): AdOrOrganicFilterOption | undefined {
    switch (option) {
        case AdsOrOrganicFilterView.Ads:
            return AdOrOrganicFilterOption.Ads
        case AdsOrOrganicFilterView.Organic:
            return AdOrOrganicFilterOption.Organic
        case AdsOrOrganicFilterView.Both:
            return AdOrOrganicFilterOption.Both
        default:
            return undefined
    }
}

export function mapSearchParametersFromView(
    searchParameters: ContentSearchParamsView,
    allSocialMediaServices: SocialMediaService[]
): SearchParameters {
    let timeDuration = searchParameters.timeDuration
        ? dayjs.duration(searchParameters.timeDuration)
        : undefined

    // We have a few errors to handle with this mapping!
    const selectedSocialMediaServices = (
        searchParameters.socialMediaServiceIds ?? []
    ).map((id: number) => {
        if (typeof id !== 'number' || isNaN(id)) {
            throw new Error(`Invalid ID found in socialMediaServiceIds: ${id}`)
        }

        const matchedService = allSocialMediaServices.find(
            (service) => service.id === id
        )

        if (!matchedService) {
            throw new Error(`Service with ID ${id} not found`)
        }

        return matchedService
    })

    const selectedClassifiers: ClassifierSearchParam[] = (
        searchParameters.classifierSearchParams ?? []
    ).map((classifier) => {
        return {
            classifierName: classifier.classifierName,
            threshold: classifier.threshold
        } as ClassifierSearchParam
    })
    let adOrOrganic = mapAdOrOrganicFilterView(
        searchParameters.adsOrOrganic ?? undefined
    )
    // The GraphQL model returns an array of strings, but that's _never_ what we want. So
    // we're going to take the first one, and default to 'all' if there's nothing there.
    let moderation = searchParameters.moderationString?.[0] ?? 'all'

    let selectedGeneralClassifiers =
        searchParameters.classifierSearchParams?.map((c) => c.classifierName) ??
        []

    // The nullish coalescing in the mappings below ( `... ?? undefined`) are to make sure
    // we're not dealing with `null`s, only with `undefined`s or some default value (`[]`, `''`)
    return {
        adOrOrganic,
        contentIds: searchParameters.contentIds ?? undefined,
        endDateTime: searchParameters.before,
        keyword: searchParameters.containsText ?? '',
        moderation,
        owned: searchParameters.owned ?? undefined,
        readReceipt: searchParameters.readFilter ?? undefined,
        selectedAccountIds: searchParameters.accountIds ?? [],
        selectedClassifiers,
        selectedSocialMediaServices,
        selectedTeams: searchParameters.teamIds ?? [],
        startDateTime: searchParameters.since,
        timeDuration,
        selectedGeneralClassifiers,

        // NOT MAPPING
        // account, // Not searching by individual account -- see instead selectedAccountIds
        selectedTopics: [], // Obsolete
        selectedEmotions: [] // Obsolete
    }
}

export function mapToSearchParamsForContentOutcome(
    searchParameters: SearchParameters
): SearchParamsForContentOutcome {
    let classifierSearchParams =
        searchParameters.selectedGeneralClassifiers.map((c) => {
            return {
                classifierName: c
            }
        })

    return {
        accountIds: searchParameters.selectedAccountIds,
        adsOrOrganic: searchParameters.adOrOrganic as
            | InputMaybe<AdsOrOrganicFilterView>
            | undefined,
        before: searchParameters.endDateTime,
        classifierSearchParams,
        containsText: searchParameters.keyword,
        contentIds: searchParameters.contentIds,
        moderationString: [searchParameters.moderation],
        owned: searchParameters.owned,
        readFilter: searchParameters.readReceipt,
        since: searchParameters.startDateTime,
        socialMediaServiceIds: searchParameters.selectedSocialMediaServices.map(
            (smsService) => smsService.id
        ),
        teamIds: searchParameters.selectedTeams,
        timeDuration: searchParameters.timeDuration?.toISOString(),
        timeOffset: undefined
    }
}
