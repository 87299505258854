import { Link, useParams } from 'react-router-dom'
import { useGetAccountQuery } from '../api/client'
import NotificationConfigurationList from '../components/notifications/NotificationConfigurationList'
import SimpleLoader from '../components/SimpleLoader'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'

type ParamTypes = {
    accountId: string
}

function NotificationConfigurationSettingsPage() {
    const { accountId } = useParams<ParamTypes>()

    const parsedAccountId = parseInt(accountId as string)

    // Load the account
    const { data: accountData, loading: accountLoading } = useGetAccountQuery({
        variables: { accountId: parsedAccountId }
    })

    const account = accountData?.getAccount

    return (
        <TextPageContainer>
            <TextPageHeader
                title="Manage notifications"
                subtitle="Create, edit and delete Courier notifications"
            />
            <Link
                to={`/account/${accountId}/settings`}
                className="text-primary-600 underline"
            >
                Back to settings
            </Link>

            {accountLoading && <SimpleLoader loading={true} />}
            {account && <NotificationConfigurationList account={account} />}
        </TextPageContainer>
    )
}

export default NotificationConfigurationSettingsPage
