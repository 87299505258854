import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import mixpanel from 'mixpanel-browser'
import {
    AccountRecord,
    ContentFeedView,
    SocialMediaService,
    SocialMediaServiceEnum
} from '../../../api/client'
import { getClassifierSearchParam } from '../../../models/ClassifierSearchParam'
import {
    EMOTIONS_LIST,
    EMOTION_THRESHOLD,
    TOPICS_LIST,
    TOPICS_THRESHOLD
} from '../../../pages/IntelligencePage'
import { getSocialMediaServiceEnum } from '../../../util/types'
import AdOrOrganicFilterComboBox from '../../AdOrOrganicComboBox'
import ContentFeedEditDialog from '../../feeds/ContentFeedEditDialog'
import ReadReceiptFilterComboBox from '../../read-receipt/ReadReceiptFilterComboBox'
import SearchInput from '../../SearchInput'
import SummaryStatistics from '../../SummaryStatistics'
import TranslationSettings from '../../translation/TranslationSettings'
import AccountFilter from './AccountFilter'
import DateTimePickerContainer from './DateTimePickerContainer'
import EmotionFilter from './EmotionFilter'
import GeneralClassifierFilter from './GeneralClassifierFilter'
import SocialMediaServiceFilter from './SocialMediaServiceFilter'
import TeamFilter from './TeamFilter'
import TopicFilter from './TopicFilter'
import SearchParameters from './searchParameters'
import { useRecoilState } from 'recoil'
import { userState } from '../../../store/DomainStore'

dayjs.extend(duration)

type Props = {
    account?: AccountRecord
    allSocialMediaServices: SocialMediaService[]
    contentFeed?: ContentFeedView
    searchParameters: SearchParameters
    onSearchParameterChange: (searchParams: SearchParameters) => void
    showEmotionsSearch: boolean
    showProfileSearch: boolean
    showReadStatusSearch: boolean
    showSocialMediaServiceSearch: boolean
    showTeamSearch: boolean
    showTopicsSearch: boolean
}

export default function ContentSearchBar(props: Props) {
    const [user] = useRecoilState(userState)
    const {
        account,
        allSocialMediaServices,
        contentFeed,
        onSearchParameterChange,
        searchParameters,
        showEmotionsSearch,
        showProfileSearch,
        showReadStatusSearch,
        showSocialMediaServiceSearch,
        showTeamSearch,
        showTopicsSearch
    } = props
    let socialMediaService: SocialMediaServiceEnum | undefined = undefined
    if (account?.socialMediaServiceShortName) {
        socialMediaService = getSocialMediaServiceEnum(
            account.socialMediaServiceShortName
        )
    }

    return (
        <div className="px-4 py-4 flex flex-row flex-wrap gap-x-4 gap-y-4 ">
            {showTeamSearch && (
                <TeamFilter
                    teamIds={searchParameters.selectedTeams}
                    onUpdateSelectedTeams={(teamIds: number[]) => {
                        mixpanel.track('Search - content - team', { teamIds })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedTeams: teamIds,
                            selectedAccountIds: []
                        })
                    }}
                />
            )}
            {showSocialMediaServiceSearch && (
                <SocialMediaServiceFilter
                    onSocialMediaServiceListChange={(
                        socialMediaServiceIds: number[]
                    ) => {
                        mixpanel.track(
                            'Search - content - social media service',
                            { socialMediaServiceIds }
                        )
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedSocialMediaServices:
                                allSocialMediaServices.filter((service) =>
                                    socialMediaServiceIds.includes(service.id)
                                )
                        })
                    }}
                    allSocialMediaServices={allSocialMediaServices}
                    selectedSocialMediaServices={
                        searchParameters.selectedSocialMediaServices
                    }
                />
            )}
            {showProfileSearch && (
                <AccountFilter
                    selectedAccountIds={searchParameters.selectedAccountIds}
                    selectedTeamIds={searchParameters.selectedTeams}
                    onUpdateSelectedAccounts={(accounts: AccountRecord[]) => {
                        mixpanel.track('Search - content - profiles', {
                            accountIds: accounts.map((account) => account.id)
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedAccountIds: accounts.map(
                                (account) => account.id
                            )
                        })
                    }}
                />
            )}

            <SummaryStatistics
                onModerationChange={(moderation: string) => {
                    mixpanel.track('Search - content - moderation', {
                        moderation
                    })
                    onSearchParameterChange({ ...searchParameters, moderation })
                }}
                selectedModeration={searchParameters.moderation}
                accountIds={
                    account ? [account.id] : searchParameters.selectedAccountIds
                }
                teamIds={searchParameters.selectedTeams}
                selectedSocialMediaServices={
                    searchParameters.selectedSocialMediaServices
                }
                keyword={searchParameters.keyword}
                selectedClassifiers={[
                    ...searchParameters.selectedEmotions.map((emotion) =>
                        getClassifierSearchParam(emotion, EMOTION_THRESHOLD)
                    ),
                    ...searchParameters.selectedTopics.map((topic) =>
                        getClassifierSearchParam(topic, TOPICS_THRESHOLD)
                    )
                ]}
                startDateTime={searchParameters.startDateTime}
                endDateTime={searchParameters.endDateTime}
                timeDuration={searchParameters.timeDuration}
            />

            <SearchInput
                keyword={searchParameters.keyword}
                onKeywordChange={(keyword: string) => {
                    mixpanel.track('Search - content - keyword', { keyword })
                    onSearchParameterChange({ ...searchParameters, keyword })
                }}
                onSearch={(keyword: string) => {
                    mixpanel.track('Search - content - keyword', { keyword })
                    onSearchParameterChange({ ...searchParameters, keyword })
                }}
            />

            <DateTimePickerContainer
                startDateTime={searchParameters.startDateTime}
                endDateTime={searchParameters.endDateTime}
                timeDuration={searchParameters.timeDuration}
                onDateChange={(
                    startDateTime?: Dayjs,
                    endDateTime?: Dayjs,
                    timeDuration?: Duration
                ) => {
                    if (startDateTime) {
                        mixpanel.track('Search - content - start date time', {
                            startDateTime: startDateTime.toISOString()
                        })
                    }
                    if (endDateTime) {
                        mixpanel.track('Search - content - end date time', {
                            endDateTime: endDateTime.toISOString()
                        })
                    }
                    if (timeDuration) {
                        mixpanel.track('Search - content - duration', {
                            timeDuration: timeDuration.toISOString()
                        })
                    }
                    onSearchParameterChange({
                        ...searchParameters,
                        startDateTime,
                        endDateTime,
                        timeDuration
                    })
                }}
                useDefault={true}
            />

            {showReadStatusSearch && (
                <ReadReceiptFilterComboBox
                    selection={searchParameters.readReceipt}
                    onChange={(newSelection) => {
                        onSearchParameterChange({
                            ...searchParameters,
                            readReceipt: newSelection
                        })
                    }}
                />
            )}
            <GeneralClassifierFilter
                searchParameters={searchParameters}
                onUpdateSelectedClassifiers={(classifiers: string[]) => {
                    mixpanel.track('Search - content - classifier', {
                        classifiers
                    })
                    onSearchParameterChange({
                        ...searchParameters,
                        selectedGeneralClassifiers: classifiers
                    })
                }}
            />

            {showEmotionsSearch && !!searchParameters.selectedEmotions && (
                <EmotionFilter
                    allEmotions={EMOTIONS_LIST}
                    selectedEmotions={searchParameters.selectedEmotions}
                    onUpdateSelectedEmotions={(emotions: string[]) => {
                        mixpanel.track('Search - content - emotion', {
                            emotions
                        })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedEmotions: emotions
                        })
                    }}
                />
            )}

            {showTopicsSearch && !!searchParameters.selectedTopics && (
                <TopicFilter
                    allTopics={TOPICS_LIST}
                    selectedTopics={searchParameters.selectedTopics}
                    onUpdateSelectedTopics={(topics: string[]) => {
                        mixpanel.track('Search - content - topic', { topics })
                        onSearchParameterChange({
                            ...searchParameters,
                            selectedTopics: topics
                        })
                    }}
                />
            )}
            <AdOrOrganicFilterComboBox
                selection={searchParameters.adOrOrganic}
                onChange={(newSelection) => {
                    onSearchParameterChange({
                        ...searchParameters,
                        adOrOrganic: newSelection
                    })
                }}
                socialMediaService={socialMediaService}
            />

            <TranslationSettings />

            {user.feedsEnabled && (
                <ContentFeedEditDialog
                    contentFeed={contentFeed}
                    searchParameters={searchParameters}
                />
            )}

            <div className="flex flex-row items-center">
                {/* This component will be used in the second step of this task */}
                {/* <SuggestedRepliesToggle
                    searchParameters={searchParameters}
                    onSearchParameterChange={onSearchParameterChange}
                /> */}
            </div>
            {/* This component will be used in the second step of this task */}
            {/* <RepliesSelectDropDown
                searchParameters={searchParameters}
                onSearchParameterChange={onSearchParameterChange}
            /> */}
        </div>
    )
}
