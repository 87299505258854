import _ from 'lodash'
import { Tooltip } from 'react-tooltip'
import { useRecoilState } from 'recoil'
import { ContentOutcomeView, ReadReceiptView } from '../../api/client'
import { userState } from '../../store/DomainStore'
import { RelativeDateDisplay } from '../date/StandardDateDisplay'
import ArwenUser from '../user/ArwenUser'

type Props = {
  content: ContentOutcomeView
}

/**
 * This displays a list of all the people that have read this content.
 *
 * It acts as a clear indicator that this content has already been looked at by someone.
 *
 * It has a drop down to list those individuals and the times they read the content.
 */
export default function ReadOthersStatusDisplay(props: Props) {
  const { content } = props

  const [user] = useRecoilState(userState)

  // Has the current user read this content?
  const readByMe: ReadReceiptView | undefined = content.readReceipts.find(
    (receipt) => receipt.userId === user.id
  )

  // There are sometimes duplicate read records so dedupe just in case.
  const uniqueReadReceipts = _.uniqBy(content.readReceipts, 'userId')

  // Get a list of all the other people who have read the comment excluding the current user.
  const readByList = uniqueReadReceipts.filter((receipt) => {
    return receipt.userId !== user.id
  })

  // Generate the tooltip. I'm not 100% happy with this design - I couldn't get the simple loader to work. An improvement would be to move the loading indicator out of the ArwenUser, preload all the users whilst showing a single load indicator and then provide the information to the ArwenUser. Just quite a bit of rewrite for something fairly minor.
  const readByNames = readByList.map((receipt) => {
    return (
      <div className="pr-1 flex flex-row gap-1 m-2" key={receipt.userId + "_" + receipt.contentOutcomeId}>
        <ArwenUser user_id={receipt.userId} /> read this{' '}
        <RelativeDateDisplay date={receipt.createdAt} />
      </div>
    )
  })

  const readByYouLine = readByMe ? (
    <div className="flex flex-row gap-1 m-2">
      You read this <RelativeDateDisplay date={readByMe.createdAt} />
    </div>
  ) : null

  return (
    <>
      <div
        className="flex flex-row items-center "
        data-tooltip-id={`tooltip-read-by_${content.id}`}
      >
        {uniqueReadReceipts && uniqueReadReceipts.length > 0 && (
          <div className="pr-1">Read by </div>
        )}
        {readByMe && <div className="pr-1">you</div>}
        {readByMe && readByList && readByList.length > 0 && (
          <div className="pr-1">and </div>
        )}
        {readByList && readByList.length > 0 && (
          <div className="flex flex-row">
            <div className="flex flex-row ">
              {readByList.length} others
            </div>
          </div>
        )}
      </div>

      <Tooltip id={`tooltip-read-by_${content.id}`}>
        <div className="w-96">
          {readByYouLine}
          {readByNames}
        </div>
      </Tooltip>
    </>
  )
}
