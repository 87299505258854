import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { SyncLoader } from 'react-spinners'
import { NotificationConfigurationView } from '../../api/client'

interface NotificationsConfigurationForm {
    recipientListId: string
    templateId: string
}

function NotificationConfigurationForm({
    notificationConfiguration,
    onSave,
    onCancel,
    saving
}: {
    notificationConfiguration: NotificationConfigurationView | null
    onSave: (templateId: string, recipientListId: string, id?: number) => void
    onCancel: () => void
    saving: boolean
}) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<NotificationsConfigurationForm>({
        defaultValues: {
            recipientListId: notificationConfiguration?.recipientListId || '',
            templateId: notificationConfiguration?.notificationTemplateId || ''
        }
    })

    const onSubmit = (data: NotificationsConfigurationForm) => {
        onSave(
            data.templateId,
            data.recipientListId,
            notificationConfiguration?.id
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="space-y-2">
                <label
                    htmlFor="recipientListId"
                    className="block text-gray-700 dark:text-gray-300"
                >
                    Recipient List ID, e.g. FintechTest
                </label>
                <input
                    type="text"
                    id="recipientListId"
                    className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded-md shadow focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-gray-100 dark:bg-gray-700"
                    {...register('recipientListId', {
                        required: true
                    })}
                />
                {errors.recipientListId && (
                    <p className="text-red-500 text-sm">
                        Please provide a valid Recipient List ID
                    </p>
                )}
            </div>

            <div className="space-y-2">
                <label
                    htmlFor="templateId"
                    className="block text-gray-700 dark:text-gray-300"
                >
                    Template ID, e.g. 075VYPVB7VM6DZG4D16GD8W58ZS1
                </label>
                <input
                    type="text"
                    id="templateId"
                    className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded-md shadow focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-gray-100 dark:bg-gray-700"
                    {...register('templateId', {
                        required: true
                    })}
                />
                {errors.recipientListId && (
                    <p className="text-red-500 text-sm">
                        Please provide a valid Template ID
                    </p>
                )}
            </div>

            <div className="flex ">
                <button
                    type="submit"
                    className={clsx(
                        'px-4 py-2 ',
                        'rounded-lg shadow',
                        'disabled:bg-gray-600',
                        'bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500'
                    )}
                    disabled={saving}
                >
                    Save Settings
                </button>
                <button
                    onClick={onCancel}
                    type="button"
                    className="ml-4 px-4 py-2 text-primary-600 border border-white hover:border-primary-700 hover:border hover:shadow rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                    Cancel
                </button>
                {saving ? (
                    <div className="mx-4">
                        <SyncLoader
                            loading={true}
                            color="#CF3C28"
                            size={'4px'}
                        />
                    </div>
                ) : null}
            </div>
        </form>
    )
}

export default NotificationConfigurationForm
