import { Link, useParams } from 'react-router-dom'
import {
    useCheckMetaAuthorisationForAccountQuery,
    useCheckMetaSubscriptionForAccountQuery,
    useCreateMetaSubscriptionForAccountLazyQuery,
    useDebugMetaAuthorisationForAccountQuery,
    useGetAccountQuery
} from '../api/client'
import Button from '../components/Button'
import SimpleLoader from '../components/SimpleLoader'
import { TextPageContainer } from '../containers/TextPageContainer'
import { TextPageHeader } from '../containers/TextPageHeader'
import WarningCallout from '../components/callout/WarningCallout'

type ParamTypes = {
    accountId: string
}

/**
 * This page checks the validity of profiles - currently only Meta ones.
 *
 * It checks the subscription status and allows a subscription to be created and it checks the authorisation.
 */
function AccountValidationPage() {
    const { accountId } = useParams<ParamTypes>()

    const parsedAccountId = parseInt(accountId as string)

    const { data: accountData, loading: accountLoading } = useGetAccountQuery({
        variables: { accountId: parsedAccountId }
    })

    const {
        data: checkData,
        loading: checkDataLoading,
        error: checkDataError
    } = useCheckMetaSubscriptionForAccountQuery({
        variables: { accountId: parsedAccountId }
    })

    const [
        createSubscription,
        {
            data: createSubscriptionData,
            loading: createSubscriptionLoading,
            error: createSubscriptionError
        }
    ] = useCreateMetaSubscriptionForAccountLazyQuery({
        variables: { accountId: parsedAccountId }
    })

    const {
        data: checkAuthData,
        loading: checkAuthDataLoading,
        error: checkAuthDataError
    } = useCheckMetaAuthorisationForAccountQuery({
        variables: { accountId: parsedAccountId }
    })

    const {
        data: debugAuthData,
        loading: debugAuthDataLoading,
        error: debugAuthDataError
    } = useDebugMetaAuthorisationForAccountQuery({
        variables: { accountId: parsedAccountId }
    })

    const account = accountData?.getAccount

    return (
        <TextPageContainer>
            <TextPageHeader title="Meta profile validation" />
            <Link
                to={`/account/${accountId}/settings`}
                className="text-primary-600 underline"
            >
                Back to settings
            </Link>
            {accountLoading && <SimpleLoader loading={true} />}
            {account && (
                <>
                    <div className="flex flex-col mb-32">
                        <p className="mb-0">
                            Meta profiles can become problematic for two reasons
                            <ul>
                                <li>
                                    The profiles does not receive any content.
                                    This is due to a missing subscription with
                                    Meta.
                                </li>
                                <li>
                                    The profile fails to take hide content or
                                    take other actions. This is because the
                                    profile has become unauthorised.
                                </li>
                            </ul>
                            This page lets you check or repair a subscription
                            and check to see if a profile is still authorised.
                        </p>
                        <h2>Subscription Check</h2>
                        <div>
                            {checkDataLoading && (
                                <div>
                                    <p className="mt-0">
                                        Checking subscription{' '}
                                    </p>
                                    <SimpleLoader loading={true} />
                                </div>
                            )}
                            {checkDataError && (
                                <div>
                                    <p className="mt-0">
                                        <WarningCallout>
                                            There was an error checking the
                                            subscription
                                        </WarningCallout>
                                    </p>
                                    <code>{checkDataError.message}</code>
                                </div>
                            )}

                            {checkData &&
                                checkData.checkMetaSubscriptionForAccount && (
                                    <div className="mt-0">
                                        This profile has a valid subscription
                                    </div>
                                )}
                            {checkData &&
                                !checkData.checkMetaSubscriptionForAccount && (
                                    <div className="mt-0">
                                        <WarningCallout>
                                            This profile does not have a
                                            subscription
                                        </WarningCallout>
                                    </div>
                                )}
                            <div className="mt-4">
                                <Button
                                    primary={true}
                                    text="Create (or recreate) subscription"
                                    size="medium"
                                    type="button"
                                    onClick={() => {
                                        createSubscription({
                                            variables: {
                                                accountId: parsedAccountId
                                            }
                                        })
                                    }}
                                />
                                {createSubscriptionData?.createMetaSubscriptionForAccount && (
                                    <div className="mt-2">
                                        Subscription created
                                    </div>
                                )}
                                {createSubscriptionData &&
                                    !createSubscriptionData.createMetaSubscriptionForAccount && (
                                        <div className="mt-2">
                                            Failed to create subscription
                                        </div>
                                    )}
                                {createSubscriptionLoading && (
                                    <div className="mt-2">
                                        <p className="mt-0">
                                            Creating subscription{' '}
                                        </p>
                                        <SimpleLoader loading={true} />
                                    </div>
                                )}
                                {createSubscriptionError && (
                                    <div className="mt-2">
                                        <p className="mt-0">
                                            There was an error creating the
                                            subscription
                                        </p>
                                        <code>
                                            {createSubscriptionError.message}
                                        </code>
                                    </div>
                                )}
                            </div>
                        </div>
                        <h2>Authorisation Check</h2>
                        <p className="mt-0">
                            This runs a quick check to see if the token we have
                            for the profile is still valid.
                        </p>
                        {checkAuthDataLoading && (
                            <div>
                                <p className="mt-0">Checking authorisation </p>
                                <SimpleLoader loading={true} />
                            </div>
                        )}
                        {checkAuthDataError && (
                            <div>
                                <p className="mt-0">
                                    <WarningCallout>
                                        There was an error checking the
                                        subscription
                                    </WarningCallout>
                                </p>
                                <code>{checkAuthDataError.message}</code>
                            </div>
                        )}

                        {checkAuthData &&
                            checkAuthData.checkMetaAuthorisationForAccount && (
                                <div className="mt-0">
                                    This profile has a valid authorisation
                                </div>
                            )}
                        {checkAuthData &&
                            !checkAuthData.checkMetaAuthorisationForAccount && (
                                <div className="mt-0">
                                    <p>
                                        <WarningCallout>
                                            This profile does not have a valid
                                            authorisation. You will have to ask
                                            the user to reauthorise.
                                        </WarningCallout>
                                    </p>
                                </div>
                            )}
                        <h2>Authorisation Debug</h2>
                        <p>
                            This debug sometimes fails with the error "Please
                            reduce the amount of data you're asking for, then
                            retry your request". If that happens then reload the
                            page and try again.
                        </p>
                        <div>
                            {debugAuthDataLoading && (
                                <div>
                                    <p className="mt-0">
                                        Debugging authorisation{' '}
                                    </p>
                                    <SimpleLoader loading={true} />
                                </div>
                            )}
                            {debugAuthDataError && (
                                <div>
                                    <p className="mt-0">
                                        There was an error checking the
                                        subscription
                                    </p>
                                    <code>{debugAuthDataError.message}</code>
                                </div>
                            )}

                            {debugAuthData &&
                                debugAuthData.debugMetaAuthorisationForAccount &&
                                debugAuthData.debugMetaAuthorisationForAccount
                                    .isValid && (
                                    <div className="mt-0">
                                        This profile has a valid authorisation
                                    </div>
                                )}
                            {debugAuthData &&
                                debugAuthData.debugMetaAuthorisationForAccount &&
                                !debugAuthData.debugMetaAuthorisationForAccount
                                    .isValid && (
                                    <div className="mt-0">
                                        <p>
                                            <WarningCallout>
                                                This profile does not have a
                                                valid authorisation
                                            </WarningCallout>
                                        </p>
                                    </div>
                                )}

                            {debugAuthData &&
                                debugAuthData.debugMetaAuthorisationForAccount && (
                                    <>
                                        <a
                                            href="https://developers.facebook.com/docs/graph-api/reference/v21.0/debug_token"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Meta Debug Token API documentation
                                        </a>
                                        <div className="mt-6 border-t border-gray-100">
                                            <dl className="divide-y divide-gray-100">
                                                <DescriptionItem
                                                    name="Application"
                                                    description={`${debugAuthData.debugMetaAuthorisationForAccount.application} (app id: ${debugAuthData.debugMetaAuthorisationForAccount.appId})`}
                                                />

                                                <DescriptionItem
                                                    name="Is valid?"
                                                    description={`${debugAuthData.debugMetaAuthorisationForAccount.isValid}`}
                                                />
                                                <DescriptionItem
                                                    name="Scopes"
                                                    description={debugAuthData.debugMetaAuthorisationForAccount.scopes.join(
                                                        ', '
                                                    )}
                                                />
                                                <DescriptionItem
                                                    name="Date when app's access
                                                    to user data expires (dataAccessExpiresAt)"
                                                    description={new Date(
                                                        // unix time comes in two variants: seconds since epoch, and milliseconds since epoch. This one is seconds since epoch so we convert to milliseconds to create the date object
                                                        debugAuthData
                                                            .debugMetaAuthorisationForAccount
                                                            .dataAccessExpiresAt *
                                                            1000
                                                    ).toISOString()}
                                                />
                                                <DescriptionItem
                                                    name="Date when
                                                    this access token expires (expiresAt)"
                                                    description={
                                                        debugAuthData
                                                            .debugMetaAuthorisationForAccount
                                                            .expiresAt !== 0
                                                            ? new Date(
                                                                  // This is a unixtime format so is in seconds not milliseconds.
                                                                  debugAuthData
                                                                      .debugMetaAuthorisationForAccount
                                                                      .expiresAt *
                                                                      1000
                                                              ).toISOString()
                                                            : 'Not available'
                                                    }
                                                />

                                                {debugAuthData
                                                    .debugMetaAuthorisationForAccount
                                                    .issuedAt && (
                                                    <DescriptionItem
                                                        name="Issued at (not always
                                                    available)"
                                                        description={new Date(
                                                            // This is a unixtime format so is in seconds not milliseconds.
                                                            debugAuthData
                                                                .debugMetaAuthorisationForAccount
                                                                .issuedAt * 1000
                                                        ).toISOString()}
                                                    />
                                                )}
                                            </dl>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                </>
            )}
        </TextPageContainer>
    )
}

type DescriptionProps = {
    name: string
    description: string
}

function DescriptionItem(props: DescriptionProps) {
    const { name, description } = props
    return (
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="font-medium leading-6 text-gray-900">{name}</dt>
            <dd className="mt-1 leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {description}
            </dd>
        </div>
    )
}

export default AccountValidationPage
