import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Transition,
} from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { targetLanguageState } from '../../store/UIStore'
import mixpanel from 'mixpanel-browser'

function classNames(...classes: string[]): string {
    return classes.filter(Boolean).join(' ')
}

export default function LanguageSelectDropDown(): JSX.Element {
    const allLanguages: string[] = [
        'English',
        'Spanish',
        'Portuguese',
        'German',
        'French',
        'Dutch',
    ]

    const [targetLanguage, setTargetLanguage] =
        useRecoilState(targetLanguageState)
    const [query, setQuery] = useState<string>('')

    const filteredLanguage: string[] =
        query === ''
            ? allLanguages
            : allLanguages.filter((language) => {
                  return language.toLowerCase().includes(query.toLowerCase())
              })

    const handleChange = (value: string | null) => {
        if (value !== null) {
            setTargetLanguage(value)
            mixpanel.track(`Translation - language -  ${value}`)
        } else {
            console.error('No language selected')
        }
    }

    return (
        <Combobox as="div" value={targetLanguage} onChange={handleChange}>
            <div className="relative rounded-md shadow">
                <ComboboxInput
                    className="focus:ring-primary-600 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-950 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </ComboboxButton>
                <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                >
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full max-w-xs overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredLanguage.map((filteredLanguage) => (
                            <ComboboxOption
                                key={filteredLanguage}
                                value={filteredLanguage}
                                className={({ focus }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        focus
                                            ? 'bg-primary text-white'
                                            : 'text-gray-950'
                                    )
                                }
                            >
                                {({ focus, selected }) => (
                                    <>
                                        <span
                                            className={classNames(
                                                'block truncate'
                                            )}
                                        >
                                            {filteredLanguage}
                                        </span>

                                        {selected && (
                                            <span
                                                className={classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    focus
                                                        ? 'text-white'
                                                        : 'text-primary'
                                                )}
                                            >
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        )}
                                    </>
                                )}
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                </Transition>
            </div>
        </Combobox>
    )
}
