import { useGetEmbeddedDashboardQuery } from '../../api/client'
import { useContext, useEffect, useRef, useState } from 'react'
import { embedDashboard } from '@preset-sdk/embedded'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle, TitleContext } from '../../components/TitleProvider'
import { toastError } from '../../components/Notification'
import TeamFilter, {
    TeamFilterInitialSelection,
} from '../../components/content/search/TeamFilter'

type ParamTypes = {
    dashboardId: string
}

export default function ReportViewPage() {
    useTitle('Report')
    const { setTitle } = useContext(TitleContext)
    const navigate = useNavigate()

    const [teamIds, setTeamIds] = useState<number[] | null>()

    // Get the dashboard ID from the URL's query params
    const { dashboardId } = useParams<ParamTypes>()

    // Parse the dashboard ID
    let parsedDashboardId: number | null = null
    if (dashboardId) {
        const id = parseInt(dashboardId, 10)
        if (!isNaN(id)) {
            parsedDashboardId = id
        }
    }

    // Redirect if the dashboardId is invalid
    useEffect(() => {
        if (!dashboardId || parsedDashboardId === null) {
            navigate('/not-found', { replace: true })
        }
    }, [dashboardId, parsedDashboardId, navigate])

    const { data, loading, error, refetch } = useGetEmbeddedDashboardQuery({
        // Default to 0 if invalid. We need to set something because hooks can't be called conditionally.
        variables: { dashboardId: parsedDashboardId || 0 },
        // But we can skip the query if the ID is invalid
        skip: parsedDashboardId === null,
        onError: (error) => {
            toastError(error.message)
        },
    })

    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        // Only attempt embedding if we have data and at least one team is selected
        if (
            data &&
            data.getEmbeddedDashboard &&
            containerRef.current &&
            teamIds &&
            teamIds.length > 0
        ) {
            const {
                workspaceUrl: supersetDomain,
                dashboard: { displayName, extEmbeddedId: id },
            } = data.getEmbeddedDashboard
            setTitle(displayName)
            embedDashboard({
                id,
                supersetDomain,
                mountPoint: containerRef.current,
                fetchGuestToken: async () => {
                    const result = await refetch()
                    if (result.data && result.data.getEmbeddedDashboard) {
                        return result.data.getEmbeddedDashboard.guestToken
                    }
                    throw new Error('Failed to fetch guest token')
                },
                dashboardUiConfig: {
                    hideTitle: false,
                    // We don't want to hide the chart controls, because they let the user download the data
                    hideChartControls: false,
                    filters: {
                        // Hide the filters by default
                        expanded: false,
                    },
                    urlParams: {
                        // To use this add a custom SQL filter in the Preset Dataset, that
                        // looks like this:
                        //    `teamId IN ({{url_param('teamIds')}})`
                        // Obviously your Dataset needs this column.
                        teamIds,
                    },
                },
            })
        }
    }, [data, containerRef, refetch, teamIds, setTitle])

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error fetching guest token</p>

    return (
        <div className="flex flex-col h-full">
            <div className="px-4 py-4 w-fit">
                <TeamFilter
                    teamIds={teamIds || []}
                    onUpdateSelectedTeams={(updatedTeamIds: number[]) => {
                        setTeamIds(updatedTeamIds)
                    }}
                    initialSelection={TeamFilterInitialSelection.All}
                />
            </div>

            {!teamIds || teamIds.length === 0 ? (
                <div className="px-4 py-4">Please select a team</div>
            ) : (
                <div
                    className="preset-dashboard w-full flex-1"
                    ref={containerRef}
                ></div>
            )}
        </div>
    )
}
