import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
    useGetContentFeedQuery,
    useGetSocialMediaServicesQuery,
    useGetTeamsQuery
} from '../api/client'
import { canUseBulkActions } from '../components/content/bulk-actions'
import ContentList from '../components/content/ContentList'
import ContentSearchBar from '../components/content/search/ContentSearchBar'
import SearchParameters, {
    mapSearchParametersFromView
} from '../components/content/search/searchParameters'
import { toastError } from '../components/Notification'
import Spinner from '../components/Spinner'
import { isUserAdminState, userState } from '../store/DomainStore'

dayjs.extend(duration)
dayjs.extend(relativeTime)

function ContentFeedPage() {
    const admin = useRecoilValue(isUserAdminState)
    const user = useRecoilValue(userState)
    const enableBulkActions = canUseBulkActions(user)

    const { data: getTeamsData, loading: getTeamsLoading } = useGetTeamsQuery({
        onError: () => {
            toastError('Error fetching user teams. Please contact support.')
        }
    })

    const hasMoreThanOneTeam: boolean = !!(
        getTeamsData?.getTeams?.length && getTeamsData?.getTeams?.length > 1
    )

    // We want to show the Team drop down only if it will be useful to users. Which means they are either an admin or they are part of more than one team.
    const showTeamSearch: boolean = admin || hasMoreThanOneTeam

    const { contentFeedId } = useParams<{ contentFeedId: string }>()

    const contentFeedIdNumber = Number(contentFeedId)

    let { data: feedData } = useGetContentFeedQuery({
        variables: {
            contentFeedId: contentFeedIdNumber
        },
        onError: (error) => {
            toastError(`Failed to load content feed: ${error}`)
        }
    })
    const [searchParameters, setSearchParameters] =
        useState<SearchParameters | undefined>(undefined)

    const {
        data: socialMediaServicesData,
        loading: getSocialMediaServicesLoading
    } = useGetSocialMediaServicesQuery({})
    const allSocialMediaServices =
        socialMediaServicesData?.getSocialMediaServices

    // We want to map the results of the hook to our standard SearchParameters object, for passing down.

    // Initialise search params when data becomes available
    useEffect(() => {
        if (feedData && allSocialMediaServices) {
            setSearchParameters(
                mapSearchParametersFromView(
                    feedData.getContentFeed.searchParameters,
                    allSocialMediaServices
                )
            )
        }
    }, [feedData, allSocialMediaServices])

    // Update local search params when they're changed -- doesn't re-execute the search
    const onSearchParameterChange = (
        updatedParams: Partial<SearchParameters>
    ) => {
        setSearchParameters((prevState) => ({
            ...prevState!,
            ...updatedParams
        }))
    }

    if (
        !searchParameters ||
        !allSocialMediaServices ||
        getTeamsLoading ||
        getSocialMediaServicesLoading
    ) {
        return (
            <div className="flex justify-center items-center h-96">
                <Spinner size={8} />
            </div>
        )
    }

    return (
        <>
            <div className="prose prose-stone ml-4 mt-6 mb-2 max-w-none ">
                <h1 className="text-gray-900 text-3xl">
                    {feedData?.getContentFeed.displayName}
                </h1>
            </div>
            <ContentSearchBar
                allSocialMediaServices={allSocialMediaServices}
                contentFeed={feedData?.getContentFeed}
                onSearchParameterChange={onSearchParameterChange}
                searchParameters={searchParameters}
                showEmotionsSearch={false}
                showProfileSearch={true}
                showReadStatusSearch={user.canUseReadStatus}
                showSocialMediaServiceSearch={true}
                showTeamSearch={showTeamSearch}
                showTopicsSearch={false}
            />
            <ContentList
                searchParameters={searchParameters}
                showOwningAccount={true}
                enableBulkActions={enableBulkActions}
            ></ContentList>
        </>
    )
}

export default ContentFeedPage
