import { Label } from '@aws-amplify/ui-react'
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    Field,
    Transition,
} from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { useSearchTeamsQuery } from '../../api/client'
import ArwenComboBoxOption from '../inputs/ArwenComboBoxOption'
import { ComboboxMenuOption } from '../inputs/ComboboxMenuOption'
import Spinner from '../Spinner'

type Props = {
    selectedTeams: ComboboxMenuOption[]
    setSelectedTeams: (teams: ComboboxMenuOption[]) => void
}

export default function TeamSelectMenu(props: Props) {
    const { selectedTeams, setSelectedTeams } = props
    const [searchTerm, setSearchTerm] = useState('')

    const { data: searchTeamsData, loading: searchTeamsLoading } =
        useSearchTeamsQuery({
            variables: { params: { searchTerm: searchTerm } },
        })

    const searchResult = searchTeamsData?.searchTeams ?? []

    const options = searchResult.map((team) => {
        return { id: team.id, name: team.name }
    })

    function handleAdd(team: ComboboxMenuOption) {
        const alreadySelected = selectedTeams.find((t) => t.id === team.id)
        if (!alreadySelected) {
            setSearchTerm('')
            setSelectedTeams([...selectedTeams, team])
        }
    }

    function handleRemove(team: ComboboxMenuOption) {
        setSelectedTeams(selectedTeams.filter((t) => t.id !== team.id))
    }

    return (
        <div>
            <Field>
                <Combobox
                    immediate={false}
                    multiple={false}
                    onChange={handleAdd}
                >
                    {({ open }) => (
                        <>
                            <Label className="block leading-6 text-gray-700">
                                Select teams
                            </Label>
                            <div className="relative mt-2">
                                <ComboboxInput
                                    value={searchTerm}
                                    className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-950 shadow ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                                    onChange={(event) => {
                                        setSearchTerm(event.target.value)
                                    }}
                                    displayValue={(
                                        option: ComboboxMenuOption
                                    ) => (option && option.name) || ''}
                                />
                                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </ComboboxButton>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <ComboboxOptions className="list-none px-0 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {searchTeamsLoading ? (
                                            <ComboboxOption
                                                value="Loading"
                                                disabled={true}
                                            >
                                                <div className="pl-3 text-primary">
                                                    <Spinner size={4} />
                                                </div>
                                            </ComboboxOption>
                                        ) : null}
                                        {options.map((option) => (
                                            <ComboboxOption
                                                key={option.id}
                                                value={option}
                                            >
                                                {({ focus, selected }) => (
                                                    <ArwenComboBoxOption
                                                        option={option}
                                                        selected={selected}
                                                        focus={focus}
                                                    />
                                                )}
                                            </ComboboxOption>
                                        ))}
                                    </ComboboxOptions>
                                </Transition>
                            </div>
                        </>
                    )}
                </Combobox>
            </Field>
            <div className={clsx('pt-2', 'flex flex-row flex-wrap space-x-2')}>
                {selectedTeams.map((team) => (
                    <span
                        key={team.id}
                        className={clsx(
                            'inline-flex items-center gap-x-0.5',
                            'rounded-md px-2 py-1 mt-2 text-sm font-medium',
                            ' bg-primary-50 text-primary-800 ring-1 ring-inset ring-primary-500/10'
                        )}
                    >
                        {team.name}
                        <button
                            type="button"
                            className="group relative -mr-1 h-6 w-6 rounded-sm hover:bg-primary-500/20"
                            onClick={() => handleRemove(team)}
                        >
                            <span className="sr-only">Remove</span>
                            <svg
                                viewBox="0 0 14 14"
                                className="h-6 w-6 stroke-primary-700/50 group-hover:stroke-primary-700/75"
                            >
                                <path d="M4 4l6 6m0-6l-6 6" />
                            </svg>
                            <span className="absolute -inset-1" />
                        </button>
                    </span>
                ))}
            </div>
        </div>
    )
}
