import { ContentAction, ContentActionView } from '../../../api/client'

/**
 * This returns actions based on the content and account state. It does not consider the user's permissions.
 */
export function getActions(
    contentProvider: string,
    isDeleted: boolean,
    isPublic: boolean,
    isAuthorised: boolean
): ContentAction[] {
    switch (contentProvider) {
        case 'twitter':
            return getTwitterActions(isDeleted, isPublic, isAuthorised)
        case 'instagram':
            return getInstagramActions(isDeleted, isPublic, isAuthorised)
        case 'facebook':
            return getFacebookActions(isDeleted, isAuthorised)
        case 'youtube':
            return getYouTubeActions(isAuthorised)
        case 'tikapi':
            return getTikApiActions(isAuthorised)
        case 'tiktok':
            return getTikTokActions(isAuthorised)
        case 'tiktokad':
            return getTikTokAdActions(isAuthorised)
        case 'tiktokuser':
            return getTikTokUserActions(isAuthorised)
        default:
            throw new Error(
                'Unknown content provider when trying to get actions'
            )
    }
}

/**
 * This takes a list of actions that have happened, allContentActions,
 * and finds the one that mathhes the enumberation
 *
 * This action can be inactive
 * */
export function findAction(
    allContentActions: ContentActionView[],
    currentAction: ContentAction
): ContentActionView | undefined {
    return allContentActions.find(
        (contentAction) =>
            contentAction.action.toLowerCase() === currentAction.toLowerCase()
    )
}

/**
 * Determines if the current action has been applied.
 */
export function isActionApplied(
    allContentActions: ContentActionView[],
    currentAction: ContentAction
): boolean {
    return (
        allContentActions.findIndex(
            (contentAction) =>
                contentAction.action.toLowerCase() ===
                    currentAction.toLowerCase() && contentAction.active
        ) > -1
    )
}

/**
 * TODO: would someone please put an explanation of this logic here? I don't get it.
 */
export function getTwitterActions(
    _isDeleted: boolean,
    isPublic: boolean,
    isAuthorised: boolean
): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        actions.push(ContentAction.Like)
        actions.push(ContentAction.Reply)
        if (isPublic) {
            // Can only hide public mentions on your timeline
            actions.push(ContentAction.Hide)
        } else {
            // Can only delete non-public, i.e. DMs
            actions.push(ContentAction.Delete)
        }

        actions.push(ContentAction.Mute)
        // SOMEDAY: Elon's API apocalypse: our Twitter API tier no longer supports block, so
        // we shouldn't offer it as an option.
        // actions.push(ContentAction.Block)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getInstagramActions(
    isDeleted: boolean,
    isPublic: boolean,
    isAuthorised: boolean
): ContentAction[] {
    const actions: ContentAction[] = []

    // If it's not public that means DMs, and we can't do any actions on those.
    if (isPublic) {
        if (isAuthorised) {
            if (!isDeleted) {
                // actions.push(ContentAction.Like)
                actions.push(ContentAction.Hide)
                actions.push(ContentAction.Reply)
            }
            actions.push(ContentAction.Delete)
        }

        actions.push(ContentAction.ConfirmSpam)
    }
    return actions
}

export function getFacebookActions(
    isDeleted: boolean,
    isAuthorised: boolean
): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        if (!isDeleted) {
            actions.push(ContentAction.Like)
            actions.push(ContentAction.Reply)
            actions.push(ContentAction.Hide)
        }
        actions.push(ContentAction.Delete)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getYouTubeActions(isAuthorised: boolean): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        // Deleting youtube comments is only supported for the comments of the account making the request, ie our client.
        // actions.push(ContentAction.Delete)
        // actions.push(ContentAction.Like)
        actions.push(ContentAction.Block)
        actions.push(ContentAction.Hide)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getTikApiActions(isAuthorised: boolean): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        actions.push(ContentAction.Delete)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getTikTokActions(isAuthorised: boolean): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        actions.push(ContentAction.Hide)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getTikTokAdActions(isAuthorised: boolean): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        actions.push(ContentAction.Hide)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}

export function getTikTokUserActions(isAuthorised: boolean): ContentAction[] {
    const actions: ContentAction[] = []
    if (isAuthorised) {
        actions.push(ContentAction.Hide)
        actions.push(ContentAction.Like)
        actions.push(ContentAction.Reply)
    }
    actions.push(ContentAction.ConfirmSpam)
    return actions
}
