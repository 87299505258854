import * as React from 'react'
import { classNames } from '../util/classNames'

type Props = {
    text: string | React.ReactNode
    primary: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    type: 'submit' | 'button'
    size?: 'small' | 'medium' | 'large'
    disabled?: boolean
    loading?: boolean
}

// This contains its own Spinner because the standard one isn't compatible with the button:
// it changes the height and pushes the button out of alignment.
const Spinner = ({ size = 16 }: { size?: number }) => (
    <div
        className="absolute animate-spin rounded-full border-2 border-solid border-current border-r-transparent"
        style={{
            width: `${size}px`,
            height: `${size}px`,
            borderWidth: '2px'
        }}
        role="status"
    />
)

function Button(props: Props) {
    const { text, primary, size, disabled, loading } = props

    const baseClasses =
        'relative inline-flex items-center justify-center px-3 py-2 border border-transparent rounded-md shadow focus:outline-none focus:ring-2 focus:ring-offset-2 transition ease-in-out duration-150'
    const variableClasses = primary
        ? ` text-white bg-primary hover:bg-primary `
        : ` text-gray-950 ring-gray-300 hover:bg-gray-200`

    const disabledClasses = disabled
        ? 'cursor-not-allowed pointer-events-none opacity-50'
        : ''

    let sizeClasses = ''
    let spinnerSize = 16
    if (size === 'small') {
        sizeClasses = 'px-2.5 py-1.5 text-xs h-8 min-w-[3rem]'
        spinnerSize = 12
    } else if (size === 'medium') {
        sizeClasses = 'px-3 py-2 text-sm h-10 min-w-[3.5rem]'
        spinnerSize = 16
    } else if (size === 'large') {
        sizeClasses = 'px-6 py-3 text-base h-12 min-w-[4rem]'
        spinnerSize = 20
    }

    const propsClone = (({ primary, loading, ...keys }) => keys)(props)

    return (
        <button
            disabled={disabled}
            className={`${baseClasses} ${variableClasses} ${classNames(
                sizeClasses
            )} ${disabledClasses}`}
            {...propsClone}
        >
            {loading && <Spinner size={spinnerSize} />}
            <span className={loading ? 'opacity-0' : 'opacity-100'}>
                {text}
            </span>
        </button>
    )
}

Button.defaultProps = {
    type: 'button'
}

export default Button
