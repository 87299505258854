import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AccountRecord } from '../../api/client'
import { getUserPermissions } from '../../util/auth'
import AuthoriseButton from '../authorise/AuthoriseButton'
import AutoDeleteSevereContentToggle from '../toggles/AutoDeleteSevereContentToggle'
import AutoHideSevereContentToggle from '../toggles/AutoHideSevereContentToggle'
import AutoMuteSevereAccountToggle from '../toggles/AutoMuteSevereAccountToggle'

type Props = {
    account: AccountRecord
}

export const AccountSettings = (props: Props) => {
    const { account } = props
    const [admin, setAdmin] = useState(false)

    useEffect(() => {
        async function determineIfAdmin() {
            const userPermissions = await getUserPermissions()

            setAdmin(userPermissions.isAdmin)
        }
        determineIfAdmin()
    }, [])

    return (
        <div className="px-6 flex flex-row content-between">
            <div className="flex flex-row justify-around items-baseline">
                <div className="flex flex-row">
                    <AutoMuteSevereAccountToggle account={account} />
                    <AutoHideSevereContentToggle account={account} />
                    <AutoDeleteSevereContentToggle account={account} />
                    <div className="mx-4">
                        <AuthoriseButton
                            account={account}
                            onlyWhenUnauthorised={true}
                        />
                    </div>
                </div>

                <div className="flex flex-row space-x-4">
                    {admin ? (
                        <div className="prose prose-stone prose-lg">
                            <Link
                                to={`/account/${account.id}/settings`}
                                className="text-primary-600 underline"
                            >
                                Admin settings
                            </Link>
                        </div>
                    ) : undefined}
                </div>
            </div>
        </div>
    )
}
