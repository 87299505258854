import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { ContentOutcomeView, EnrichedContent } from '../../../../api/client'
import User from '../../../../models/User'
import { isAd } from '../../../../util/ads'
import FeedbackButton from '../../../actions/buttons/FeedbackButton'
import InfoButton from '../../../actions/buttons/InfoButton'
import {
    RelativeDateDisplay,
    StandardDateDisplay
} from '../../../date/StandardDateDisplay'
import ContentMetadataModal from './ContentMetadataModal'

type Props = {
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
    user: User | undefined
}

export default function ContentMetadata(props: Props) {
    const [open, setOpen] = useState(false)
    const { content, enrichedContent, user } = props
    const showAdLabel = isAd(content)
    const [isClicked, setIsClicked] = useState(false)

    /**
     * The name we want to display might be a handle, e.g. '@jamescolepotter' or
     * it might be a name, e.g. James Cole Pottery. This depends on the service.
     */
    return (
        <div className="flex flex-row">
            {content.serviceAuthorName && (
                <div className="pr-2 font-medium">
                    {content.serviceAuthorName}
                </div>
            )}
            {content.serviceAuthorDisplayUsername &&
                content.socialMediaAuthorLink && (
                    <div className="text-primary-600 hover:text-primary-900 pr-1">
                        <a
                            href={content.socialMediaAuthorLink || ''}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {content.serviceAuthorDisplayUsername}
                        </a>
                    </div>
                )}
            {content.serviceAuthorDisplayUsername &&
                !!!content.socialMediaAuthorLink && (
                    <div className="pr-1 text-gray-700">
                        {content.serviceAuthorDisplayUsername}
                    </div>
                )}
            <div className="pr-1 text-gray-700">·</div>
            <div
                className="pr-1 text-gray-700"
                data-cy-date={content.datePosted}
            >
                <RelativeDateDisplay date={content.datePosted} />
            </div>
            <div className="pr-1 text-gray-600">
                ( <StandardDateDisplay date={content.datePosted} /> )
            </div>
            <div className="flex flex-col items-center justify-center space-y-4">
                <div className="flex">
                    {!isClicked ? (
                        <div className="pl-1">
                            <button
                                data-tooltip-id="tooltip-id"
                                data-tooltip-content="Click for more comment actions"
                                className="w-6 h-6 p-0 transition duration-300 ease-in-out text-gray-700"
                                type="button"
                                onClick={() => setIsClicked(true)}
                            >
                                <div className="relative">
                                    <EllipsisHorizontalIcon />
                                </div>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className="pl-1">
                                <InfoButton
                                    tooltip="Show all details"
                                    onActionClick={() => {
                                        mixpanel.track(
                                            'Content - info button clicked',
                                            {
                                                accountId: content.account?.id,
                                                contentId: content.id
                                            }
                                        )
                                        setOpen(true)
                                    }}
                                />
                            </div>
                            <div className="pl-2">
                                <FeedbackButton
                                    tooltip="Provide feedback on how we've managed this comment"
                                    onActionClick={() => {
                                        mixpanel.track(
                                            'Content - feedback button clicked',
                                            {
                                                accountId: content.account?.id,
                                                contentId: content.id
                                            }
                                        )
                                        const contentId = content.id
                                        const userId = user?.id
                                        const userName = user?.username
                                        const queue = content.moderation
                                        const accountName =
                                            content.account?.accountName
                                        const accountId = content.account?.id
                                        const service =
                                            content.socialMediaServiceName
                                        const teamId = user?.team?.id
                                        const teamName = user?.team?.name

                                        const url = `https://m3c1swe06yw.typeform.com/to/XOI72yex#content_id=${contentId}&user_id=${userId}&user_name=${userName}&queue=${queue}&account_name=${accountName}&account_id=${accountId}&service=${service}&team_id=${teamId}&team_name=${teamName}`
                                        window.open(url, '_blank')
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <ContentMetadataModal
                open={open}
                setOpen={setOpen}
                content={content}
                enrichedContent={enrichedContent}
            />
            {showAdLabel ? (
                <>
                    <span
                        data-tooltip-id="tooltip-id"
                        data-tooltip-content="This comment was on an ad"
                        className="ml-auto inline-flex items-center rounded-md bg-green-50 px-1 py-0 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    >
                        Ad
                    </span>
                </>
            ) : undefined}
        </div>
    )
}
