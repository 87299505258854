import React from 'react'
import { useForm } from 'react-hook-form'
import { AccountRecord } from '../../../api/client'
import _ from 'lodash'
import { SyncLoader } from 'react-spinners'
import clsx from 'clsx'
import WarningCallout from '../../callout/WarningCallout'

interface ProfileSettingsForm {
    shadowBanningEnabled: boolean
    shadowBanningThreshold: number
    shadowBanningRangeInDays: number
}

function ShadowBanningForm({
    account,
    onSave,
    onCancel,
    saving,
}: {
    account: AccountRecord
    onSave: (account: AccountRecord) => void
    onCancel: () => void
    saving: boolean
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ProfileSettingsForm>({
        defaultValues: {
            shadowBanningEnabled: account.shadowBanningEnabled,
            shadowBanningThreshold: account.shadowBanningThreshold,
            shadowBanningRangeInDays: account.shadowBanningRangeInDays,
        },
    })

    const onSubmit = (data: ProfileSettingsForm) => {
        const updatedAccount = _.cloneDeep(account)
        updatedAccount.shadowBanningEnabled = data.shadowBanningEnabled
        updatedAccount.shadowBanningThreshold = data.shadowBanningThreshold
        updatedAccount.shadowBanningRangeInDays = data.shadowBanningRangeInDays

        onSave(updatedAccount)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            {/* Shadow Banning Toggle */}
            <div className="mx-4">
                <WarningCallout>
                    Auto-hiding needs to be enabled for this to work.{' '}
                    <strong>It takes 5-10 minutes to activate</strong>
                </WarningCallout>
            </div>

            <div className="flex items-center justify-between">
                <label
                    htmlFor="shadowBanning"
                    className="text-gray-700 dark:text-gray-300"
                >
                    Enable Shadow Banning
                </label>
                <input
                    type="checkbox"
                    id="shadowBanning"
                    className="w-6 h-6 text-primary-600 rounded focus:ring-2 focus:ring-primary-500"
                    {...register('shadowBanningEnabled')}
                />
            </div>

            {/* Violations Threshold */}
            <div className="space-y-2">
                <label
                    htmlFor="violationThreshold"
                    className="block text-gray-700 dark:text-gray-300"
                >
                    Number of violations
                </label>
                <input
                    type="number"
                    id="violationThreshold"
                    className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded-md shadow focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-gray-100 dark:bg-gray-700"
                    {...register('shadowBanningThreshold', {
                        required: true,
                        min: 1,
                    })}
                />
                {errors.shadowBanningThreshold && (
                    <p className="text-red-500 text-sm">
                        Please provide a valid number of violations (minimum 1).
                    </p>
                )}
            </div>

            {/* Violation Days Threshold */}
            <div className="space-y-2">
                <label
                    htmlFor="violationDays"
                    className="block text-gray-700 dark:text-gray-300"
                >
                    Days to reach threshold
                </label>
                <input
                    type="number"
                    id="violationDays"
                    className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded-md shadow focus:ring-primary-500 focus:border-primary-500 text-gray-900 dark:text-gray-100 dark:bg-gray-700"
                    {...register('shadowBanningRangeInDays', {
                        required: true,
                        min: 1,
                    })}
                />
                {errors.shadowBanningRangeInDays && (
                    <p className="text-red-500 text-sm">
                        Please provide a valid number of days (minimum 1).
                    </p>
                )}
            </div>

            {/* Submit Button */}
            <div className="flex ">
                <button
                    type="submit"
                    className={clsx(
                        'px-4 py-2 ',
                        'rounded-lg shadow',
                        'disabled:bg-gray-600',
                        'bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500'
                    )}
                    disabled={saving}
                >
                    Save Settings
                </button>
                <button
                    onClick={onCancel}
                    type="button"
                    className="ml-4 px-4 py-2 text-primary-600 border border-white hover:border-primary-700 hover:border hover:shadow rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500"
                >
                    Cancel
                </button>
                {saving ? (
                    <div className="mx-4">
                        <SyncLoader
                            loading={true}
                            color="#CF3C28"
                            size={'4px'}
                        />
                    </div>
                ) : null}
            </div>
        </form>
    )
}

export default ShadowBanningForm
