import dayjs from 'dayjs'
import { FunctionComponent } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetContentByIdQuery } from '../api/client'
import ContentList from '../components/content/ContentList'
import AccountNameTitle from '../components/sidebar/AccountNameTitle'
import useContentSearchParams from '../hooks/useContentQueryParams'

/**
 * Displays a single content item.
 *
 * Expects the contentId to be on the URL path (not the params).
 *
 * Displays using the ContentList component, because that does all the heavy lifting.
 *
 * Has to fiddle with a few of the content search params to get the content loading,
 * but there's precent for this in `SpamContentList`.
 */
const ContentPage: FunctionComponent = () => {
    const { contentId } = useParams<{ contentId: string }>()
    const id = Number(contentId)

    const { searchParameters } = useContentSearchParams()
    if (contentId) {
        searchParameters.contentIds = [id]
    }

    searchParameters.owned = undefined
    // Hack -- we know exactly what we're fetching but we won't get it unless we have a compatible time,
    // Lets make sure that the range is big enough...
    searchParameters.startDateTime = dayjs('2019-01-01')
    searchParameters.endDateTime = dayjs('3000-01-02')

    const { data } = useGetContentByIdQuery({
        variables: {
            id: id
        },
        skip: !id
    })

    return (
        <div className="self-center w-full">
            {data?.getContentById ? (
                <div className="py-6 px-2 ">
                    <AccountNameTitle account={data?.getContentById?.account} />
                    <Link
                        to={`/account/${data?.getContentById?.account.id}`}
                        className="underline text-primary-600"
                    >
                        View Profile page
                    </Link>
                </div>
            ) : null}

            <ContentList
                searchParameters={searchParameters}
                showOwningAccount={true}
                enableBulkActions={false}
                hidePagination={true}
            />
        </div>
    )
}

export default ContentPage
