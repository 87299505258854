import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../Button'
import { ContentOutcomeView, SocialMediaServiceEnum } from '../../api/client'

type Props = {
    onReply: (message: string) => void
    sendingReply: boolean
    initialReply: string
    content: ContentOutcomeView
}

type FormData = {
    comment: string
}

/**
 * Component to display a text area for a user to reply to a message.
 */
export default function NewReply(props: Props) {
    const { onReply, sendingReply, initialReply, content } = props

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors }
    } = useForm<FormData>({
        defaultValues: { comment: initialReply }
    })

    useEffect(() => {
        setValue('comment', initialReply)
    }, [initialReply, setValue])

    const onSubmit = (data: FormData) => {
        onReply(data.comment)
    }

    const TIKTOK_REPLY_CHARACTER_LIMIT = 150
    const X_REPLY_CHARACTER_LIMIT = 280
    const INSTAGRAM_REPLY_CHARACTER_LIMIT = 2200
    const FACEBOOK_REPLY_CHARACTER_LIMIT = 8000

    const maxReplyLength = (() => {
        switch (content.account.socialMediaServiceShortName?.toLowerCase()) {
            case SocialMediaServiceEnum.Tiktokuser.toLowerCase():
            case SocialMediaServiceEnum.Tiktokad.toLowerCase():
                return TIKTOK_REPLY_CHARACTER_LIMIT
            case SocialMediaServiceEnum.Twitter.toLowerCase():
                return X_REPLY_CHARACTER_LIMIT
            case SocialMediaServiceEnum.Instagram.toLowerCase():
                return INSTAGRAM_REPLY_CHARACTER_LIMIT
            case SocialMediaServiceEnum.Facebook.toLowerCase():
                return FACEBOOK_REPLY_CHARACTER_LIMIT
            default:
                return 150 // Default value if no match - this should never happen
        }
    })()

    const comment = watch('comment', initialReply)
    const charactersUsed = comment?.length || 0

    return (
        <div className="flex items-start space-x-4 w-full">
            <div className="min-w-0 flex flex-col w-full">
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="mt-10 ml-8 pr-8 opacity-100 relative w-full">
                        <label htmlFor="comment" className="sr-only">
                            Add your response
                        </label>
                        <textarea
                            rows={5}
                            id="comment"
                            className="w-full border border-gray-300 shadow-md rounded-lg  resize-none  text-gray-900 placeholder:text-gray-400 focus:ring-0"
                            placeholder="Reply to this message..."
                            maxLength={maxReplyLength}
                            {...register('comment', {
                                required: 'Comment is required',
                                maxLength: {
                                    value: maxReplyLength,
                                    message: `Comment cannot exceed ${maxReplyLength} characters`
                                }
                            })}
                        />
                    </div>

                    <div className="flex flex-row justify-between ml-8 mt-2 items-baseline">
                        {errors.comment && (
                            <p className="text-red-500">
                                {errors.comment.message}
                            </p>
                        )}
                        <div></div>
                        <div className="flex flex-row items-baseline">
                            <div className="text-gray-700 text-sm mr-4">
                                {`${charactersUsed}/${maxReplyLength}`}
                            </div>
                            <Button
                                primary={true}
                                disabled={sendingReply}
                                onClick={handleSubmit(onSubmit)}
                                text="Reply"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
