import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

type Props = {
    selected: ArwenDetailedListBoxItem
    options: ArwenDetailedListBoxItem[]
    onSelect: (optionName: ArwenDetailedListBoxItem) => void
    widthClass: string
    label: string
}

export type ArwenDetailedListBoxItem = {
    id: string
    title: string
    description: string
    disabled?: boolean
}

export default function ArwenDetailedListBox(props: Props) {
    const { selected, options, onSelect, widthClass, label } = props

    return (
        <Listbox
            value={selected}
            onChange={(event) => {
                onSelect(event)
            }}
        >
            <div className={`${widthClass} `}>
                <Label className="sr-only">{label}</Label>
                <div className="relative flex flex-grow">
                    <div className="flex-grow inline-flex divide-x divide-gray-300 rounded-md shadow-sm border border-gray-300">
                        <div className="flex-grow inline-flex w-36 items-center rounded-l-md bg-white px-3 py-2 text-gray-950">
                            <p className="text-sm ">{selected.title}</p>
                        </div>
                        <ListboxButton className="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 ">
                            <span className="sr-only">{label}</span>
                            <ChevronDownIcon
                                aria-hidden="true"
                                className="h-5 w-5 "
                            />
                        </ListboxButton>
                    </div>

                    <ListboxOptions className="absolute left-0 z-10 mt-2 max-w-full origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {options.map((option) => (
                            <ListboxOption
                                key={option.title}
                                value={option}
                                disabled={option.disabled}
                                className={`group cursor-default select-none p-4 text-sm text-gray-900 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 ${
                                    option.disabled
                                        ? 'opacity-50 cursor-not-allowed'
                                        : ''
                                }`}
                            >
                                <div className="flex flex-col">
                                    <div className="flex justify-between">
                                        <p
                                            className={`font-normal group-data-[selected]:font-medium ${
                                                option.disabled
                                                    ? 'text-gray-400'
                                                    : ''
                                            }`}
                                        >
                                            {option.title}
                                        </p>
                                    </div>
                                    <p
                                        className={`mt-2 text-gray-500 group-data-[focus]:text-gray-700 ${
                                            option.disabled
                                                ? 'text-gray-400'
                                                : ''
                                        }`}
                                    >
                                        {option.description}
                                    </p>
                                </div>
                            </ListboxOption>
                        ))}
                    </ListboxOptions>
                </div>
            </div>
        </Listbox>
    )
}
