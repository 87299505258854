import { DialogPanel } from '@headlessui/react'
import { useState } from 'react'
import {
    ModerationBoundaryView,
    useGetAccountQuery,
    useGetClassifierDetailsQuery,
    useGetModerationBoundariesQuery
} from '../../api/client'
import SimpleModal from '../SimpleModal'
import EditModerationBoundary from './EditModerationBoundary'
import ModerationBoundaryList from './ModerationBoundaryList'

import { Link } from 'react-router-dom'
import SimpleLoader from '../SimpleLoader'
import { ModerationTemplate } from './ModerationTemplates'
import NewModerationBoundary from './NewModerationBoundary'
import TemplateSelection from './ModerationBoundaryTemplateSelection'
import { toastError } from '../Notification'

export enum Queue {
    SAFE = 'SAFE',
    SUSPECT = 'SUSPECT',
    SEVERE = 'SEVERE'
}

type Props = {
    accountId: number
}

export default function ModerationContent(props: Props) {
    const { accountId } = props

    const [editOpen, setEditOpen] = useState(false)
    const [selectedBoundary, setSelectedBoundary] =
        useState<ModerationBoundaryView | undefined>()
    const [selectedTemplate, setSelectedTemplate] =
        useState<ModerationTemplate | undefined>()

    const { data: accountData } = useGetAccountQuery({
        variables: { accountId }
    })

    const { data, loading, refetch } = useGetModerationBoundariesQuery({
        variables: { accountId: accountId },
        fetchPolicy: 'network-only',
        onError: () => {
            toastError('Failed to get moderation boundaries')
        }
    })

    const { data: classifierDetails } = useGetClassifierDetailsQuery()
    const classifierDetailsData = classifierDetails?.getClassifierDetails || []
    const sortedClassifierDetails = classifierDetailsData
        .slice()
        .sort((a, b) => {
            if (a.displayName < b.displayName) {
                return -1
            }
            if (a.displayName > b.displayName) {
                return 1
            }
            return 0
        })

    const boundaries = data?.getModerationBoundaries

    const severeBoundaries =
        boundaries?.filter((boundary) => boundary.queue === Queue.SEVERE) || []

    const suspectBoundaries =
        boundaries?.filter((boundary) => boundary.queue === Queue.SUSPECT) || []

    const safeBoundaries =
        boundaries?.filter((boundary) => boundary.queue === Queue.SAFE) || []

    const account = accountData?.getAccount

    if (!accountData?.getAccount) {
        return <SimpleLoader loading={true} />
    }

    async function handleDeleteModerationBoundary(
        moderationBoundaryView: ModerationBoundaryView
    ) {
        await refetch()
    }

    function filterExcludedClassifiers(
        classifierDetails: Array<any>,
        boundaries: ModerationBoundaryView[]
    ): Array<any> {
        return classifierDetails.filter(
            (classifierDetail) =>
                boundaries.findIndex(
                    (boundary) => boundary.classifier.id === classifierDetail.id
                ) === -1
        )
    }

    return (
        <div>
            <div className="prose prose-stone prose-xlg mb-6">
                <h1>Moderation Boundaries</h1>
                <Link
                    to={`/account/${accountId}/settings`}
                    className="text-primary-600 underline"
                >
                    Back to settings
                </Link>
            </div>
            {account && (
                <TemplateSelection
                    account={account}
                    onTemplateApplied={function () {
                        refetch()
                    }}
                    selectedTemplate={selectedTemplate}
                    onSetSelectedTemplate={setSelectedTemplate}
                    classifierDetailsList={sortedClassifierDetails}
                />
            )}

            <div className="my-6">
                <a href={`#/account/${accountId}/keywords`}>
                    <span className="text-primary-600 underline">
                        Manage keywords
                    </span>
                </a>
            </div>
            <div className="prose prose-stone prose-lg mb-6">
                <h2>Severe queue </h2>
            </div>
            {loading}
            <div className="w-6/12">
                <ModerationBoundaryList
                    boundaries={severeBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-primary-600"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.severe || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                    onDelete={handleDeleteModerationBoundary}
                />
                {loading && <SimpleLoader loading={true} />}
                {account && (
                    <NewModerationBoundary
                        account={account}
                        classifierDetailsList={filterExcludedClassifiers(
                            sortedClassifierDetails,
                            severeBoundaries
                        )}
                        queue={Queue.SEVERE}
                        onCreate={async () => {
                            await refetch()
                        }}
                    />
                )}
            </div>
            <div className="prose prose-stone prose-lg mt-8 mb-6">
                <h2>Suspect queue </h2>
            </div>
            {loading}
            <div className="w-6/12">
                <ModerationBoundaryList
                    boundaries={suspectBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-suspect-400"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.suspect || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                    onDelete={handleDeleteModerationBoundary}
                />
                {loading && <SimpleLoader loading={true} />}
                {account && (
                    <NewModerationBoundary
                        account={account}
                        classifierDetailsList={filterExcludedClassifiers(
                            sortedClassifierDetails,
                            suspectBoundaries
                        )}
                        queue={Queue.SUSPECT}
                        onCreate={async () => {
                            await refetch()
                        }}
                    />
                )}
            </div>
            <div className="prose prose-stone prose-lg mt-8 mb-6">
                <h2>Safe queue </h2>
            </div>
            {loading}
            <div className="w-6/12 mb-32">
                <ModerationBoundaryList
                    boundaries={safeBoundaries || []}
                    setSelectedBoundary={setSelectedBoundary}
                    setEditOpen={setEditOpen}
                    headerClassNames="bg-safe-400"
                    showCompare={!!selectedTemplate}
                    compareWith={selectedTemplate?.safe || new Map()}
                    compareName={selectedTemplate?.displayName || ''}
                    onDelete={handleDeleteModerationBoundary}
                />
                {loading && <SimpleLoader loading={true} />}
                {account && (
                    <NewModerationBoundary
                        account={account}
                        classifierDetailsList={filterExcludedClassifiers(
                            sortedClassifierDetails,
                            safeBoundaries
                        )}
                        queue={Queue.SAFE}
                        onCreate={async () => {
                            await refetch()
                        }}
                    />
                )}
            </div>
            <SimpleModal
                open={editOpen}
                onClose={() => {
                    setSelectedBoundary(undefined)
                    setEditOpen(false)
                }}
            >
                <DialogPanel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <EditModerationBoundary
                        onClose={() => {
                            setEditOpen(false)
                        }}
                        onCloseAndSave={() => {
                            setEditOpen(false)
                            refetch()
                        }}
                        selectedBoundary={selectedBoundary}
                        onSelectedBoundaryUpdate={setSelectedBoundary}
                    />
                </DialogPanel>
            </SimpleModal>
        </div>
    )
}
