import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
    canViewIntelligencePageState,
    isUserAdminState,
    userState
} from '../../store/DomainStore'
import NavigationItem from './NavigationItem'
import { getAdminNavigationItems } from './admin-navigation-data'
import {
    NAV_TITLE_INTELLIGENCE,
    NAV_TITLE_REPORT,
    getNavigationItems
} from './navigation-data'
import { whichPageAreWeOn } from './page-name'

type Props = {
    sidebarOpen: boolean
}

function SidebarNav(props: Props) {
    const { sidebarOpen } = props
    const [admin] = useRecoilState(isUserAdminState)
    const [intelligencePageEnabled] = useRecoilState(
        canViewIntelligencePageState
    )
    const [user] = useRecoilState(userState)
    const location = useLocation()

    // Which sidebar nav item do we want to highlight?
    // Lets split the pathname into its components
    const paths = location.pathname.split('/')
    const pages = whichPageAreWeOn(paths)

    // Get the navigation items - by passing the page booleans these functions will highlight the correct nav item
    const navigation = getNavigationItems(
        pages.onHomePage,
        pages.onContentSearchPage,
        pages.onTeamPage,
        pages.onReportPage,
        pages.onContactUsPage,
        pages.onAboutPage,
        pages.onIntelligencePage
    )

    function generateAdminMenu() {
        const adminNavigation = getAdminNavigationItems(
            pages.onSpamPage,
            pages.onTeamAdminPage,
            pages.onUserAdminPage,
            pages.onCommenterAdminPage,
            pages.onPresalesReportPage,
            pages.onPromptTestingPage
        )

        return (
            <>
                <hr />
                <div className="text-gray-700 ml-2 pt-4 ">ADMIN</div>
                {adminNavigation.map((item) => {
                    return (
                        <NavigationItem
                            item={item}
                            sidebarOpen={sidebarOpen}
                            key={item.name}
                        />
                    )
                })}
            </>
        )
    }

    return (
        <nav className="flex-1 space-y-1 px-2 pb-4">
            {navigation.map((item) => {
                if (item.name === NAV_TITLE_REPORT) {
                    if (!user.dashboardEnabled) {
                        // User does not have this enabled - we don't want to show this link
                        return (
                            <div key={item.name}>
                                <hr />
                            </div>
                        )
                    } else {
                        return (
                            <div key={item.name}>
                                <NavigationItem
                                    item={item}
                                    sidebarOpen={sidebarOpen}
                                    key={item.name}
                                />
                                <hr />
                            </div>
                        )
                    }
                }
                if (
                    item.name === NAV_TITLE_INTELLIGENCE &&
                    !intelligencePageEnabled &&
                    !admin
                ) {
                    // User does not have this enabled - we don't want to show this link
                    return <div key={item.name}></div>
                } else {
                    return (
                        <div key={item.name}>
                            <NavigationItem
                                item={item}
                                sidebarOpen={sidebarOpen}
                                key={item.name}
                            />
                        </div>
                    )
                }
            })}

            {admin ? generateAdminMenu() : null}
        </nav>
    )
}

export default SidebarNav
