export default function ContentFeedLoadingSkeleton() {
    return (
        <div className="border-gray-300 border-solid flex animate-pulse flex-row items-stretch justify-center ">
            <div className=" bg-gray-300 w-32 h-16 rounded-sm"></div>
        </div>
    )
}

export function ContentFeedKPILoadingSkeleton() {
    return (
        <div className="border-gray-300 border-solid flex animate-pulse flex-row items-stretch justify-center w-24 ">
            <div className=" bg-gray-300 w-full h-6 rounded-sm"></div>
        </div>
    )
}
